import React from 'react';
import ToolContent from '../ToolContent';

const RecipeGenerator = () => {
  const formFields = [
    { name: 'mainIngredients', type: 'text', placeholder: 'Main Ingredients:', required: true },
    { name: 'excludedIngredients', type: 'text', placeholder: 'Excluded Ingredients:' },
    {
      name: 'cuisineType',
      type: 'select',
      placeholder: 'Select Cuisine Type:',
      options: [
        { value: 'Italian', label: 'Italian' },
        { value: 'Mexican', label: 'Mexican' },
        { value: 'Asian', label: 'Asian' },
        { value: 'American', label: 'American' },
        { value: 'Indian', label: 'Indian' },
      ],
      required: true
    },
    {
      name: 'dishType',
      type: 'select',
      placeholder: 'Select Dish Type:',
      options: [
        { value: 'Appetizer', label: 'Appetizer' },
        { value: 'Main Course', label: 'Main Course' },
        { value: 'Dessert', label: 'Dessert' },
        { value: 'Snack', label: 'Snack' },
      ],
    },
    {
      name: 'dietType',
      type: 'select',
      placeholder: 'Select Diet Type:',
      options: [
        { value: 'None', label: 'None' },
        { value: 'Vegetarian', label: 'Vegetarian' },
        { value: 'Vegan', label: 'Vegan' },
        { value: 'Gluten-Free', label: 'Gluten-Free' },
        { value: 'Keto', label: 'Keto' },
      ],
    },
    { name: 'allergies', type: 'text', placeholder: 'Allergies: if any' },
    { name: 'prepTime', type: 'number', placeholder: 'Preparation Time (in minutes):' },
    {
      name: 'mealType',
      type: 'select',
      placeholder: 'Select Meal Type:',
      options: [
        { value: 'Breakfast', label: 'Breakfast' },
        { value: 'Lunch', label: 'Lunch' },
        { value: 'Dinner', label: 'Dinner' },
        { value: 'Snack', label: 'Snack' },
      ],
      required: true
    },
    {
      name: 'difficulty',
      type: 'select',
      placeholder: 'Select Difficulty Level:',
      options: [
        { value: 'Easy', label: 'Easy' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Hard', label: 'Hard' },
      ],
    },
    { name: 'servingSize', type: 'number', placeholder: 'Serving Size:' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_RECIPE}
      title="Recipe Generator"
      imageName="recipe_image"
    />
  );
};

export default RecipeGenerator;