import React from 'react';
import ToolContent from '../ToolContent';

const StartupNameGenerator = () => {
  const formFields = [
    { name: 'businessType', type: 'text', placeholder: 'Business Type/Industry:', required: true },
    { name: 'targetAudience', type: 'text', placeholder: 'Target Audience:', required: true },
    { name: 'brandValues', type: 'textarea', placeholder: 'Brand Values/Message:' },
    {
      name: 'preferredTone',
      type: 'select',
      placeholder: 'Select Preferred Tone/Style:',
      options: [
        { value: 'modern', label: 'Modern' },
        { value: 'traditional', label: 'Traditional' },
        { value: 'playful', label: 'Playful' },
        { value: 'serious', label: 'Serious' },
        { value: 'professional', label: 'Professional' },
      ],
      required: true
    },
    { name: 'keywords', type: 'text', placeholder: 'Keywords or Themes:' },
    { name: 'competitors', type: 'text', placeholder: 'Existing Names/Competitors:' },
    {
      name: 'nameLength',
      type: 'select',
      placeholder: 'Select Name Length:',
      options: [
        { value: 'short', label: 'Short' },
        { value: 'medium', label: 'Medium' },
        { value: 'long', label: 'Long' },
      ],
      required: true
    },
    {
      name: 'domainAvailability',
      type: 'select',
      placeholder: 'Select Domain Availability:',
      options: [
        { value: 'required', label: 'Required' },
        { value: 'optional', label: 'Optional' },
        { value: 'not_needed', label: 'Not Needed' },
      ],
      required: true
    },
    { name: 'geographicFocus', type: 'text', placeholder: 'Geographic Focus:' },
    { name: 'legalConsiderations', type: 'textarea', placeholder: 'Legal Considerations:' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_STARTUP_NAMES}
      title="Startup Name Generator"
      imageName="startup_name"
    />
  );
};

export default StartupNameGenerator;