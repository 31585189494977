import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import validator from 'validator';
import { isImageGenerationLimited } from '../../utils/cookies';
import '../../css/SignupLogin.css'

const API_URL = process.env.REACT_APP_API;
// const API_URL = "http://localhost:5000";

const Signup = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleSignup = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        // Sanitize inputs using DOMPurify
        const safeUsername = DOMPurify.sanitize(username);
        const safePassword = DOMPurify.sanitize(password);
        const safeName = DOMPurify.sanitize(name);
        const safeEmail = DOMPurify.sanitize(email);

        // Validate inputs using validator
        if (!validator.isAlpha(safeName, 'en-US', { ignore: ' ' })) {
            setError('Name should only contain letters.');
            return;
        }

        if (safeName.length < 2) {
            setError('Name must be at least 2 characters long.');
            return;
        }

        if (safeName.length > 30) {
            setError('Name must be no more than 30 characters long.');
            return;
        }

        if (!validator.isEmail(safeEmail)) {
            setError('Please enter a valid email address.');
            return;
        }

        if (!validator.isAlphanumeric(safeUsername)) {
            setError('Username should only contain letters and numbers, no white spaces or symbols.');
            return;
        }

        if (safeUsername.length < 4) {
            setError('Username must be at least 4 characters long.');
            return;
        }

        if (safeUsername.length > 12) {
            setError('Username must be no more than 12 characters long.');
            return;
        }

        if (!validator.isStrongPassword(safePassword, {
            minLength: 8,
            minLowercase: 0,
            minUppercase: 0,
            minNumbers: 0,
            minSymbols: 0,
        })) {
            setError('Password must contain at least 8 characters.');
            return;
        }

        if (safePassword.length > 18) {
            setError('Password must be no more than 18 characters long.');
            return;
        }

        try {
            // eslint-disable-next-line
            const response = await axios.post(`${API_URL}/signup`,
                { username: safeUsername, password: safePassword, name: safeName, email: safeEmail },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Key': process.env.REACT_APP_KEY,
                    }
                }
            );
            setSuccess('Signup successful! Please log in.');
            setTimeout(() => navigate('/login'), 1500);
        } catch (error) {
            setError(error.response?.data?.error || 'Username or email already exists or some error occurred');
        }
    };

    return (
        <div className="ls-signup-container">
            <div className="ls-signup-form-container">
                <div className="ls-form-box">
                    <div className="ls-logo-container">
                        <Link to="/" className="ls-logo-link">
                            <img className="ls-favicon" src="/images/fav-da.png" alt="Favicon" />
                            {/* <img className="ls-logo" src="/images/devsaura2.png" alt="Logo" /> */}
                        </Link>
                    </div>
                    <h2 className="ls-form-title">Create Account</h2>
                    <form onSubmit={handleSignup} className="ls-signup-form">
                        {error && <div className="ls-error-message">{error}</div>}
                        {success && <div className="ls-success-message">{success}</div>}
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Full Name"
                            required
                            className="ls-form-input"
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                            className="ls-form-input"
                        />
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                            required
                            className="ls-form-input"
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                            className="ls-form-input"
                        />
                        <button
                            type="submit"
                            className="ls-submit-button"
                        >
                            Sign Up
                        </button>
                    </form>
                    <p className="ls-login-link-text">
                        Already have an account?{' '}
                        <Link to="/login" className="ls-login-link">
                            Login
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showImageGenerationReason, setShowImageGenerationReason] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setShowImageGenerationReason(isImageGenerationLimited() && location.state?.from === '/generate-image');
    }, [location]);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');

        // Sanitize inputs
        const safeUsername = DOMPurify.sanitize(username);
        const safePassword = DOMPurify.sanitize(password);

        // Validate inputs
        if (!validator.isAlphanumeric(safeUsername)) {
            setError('Username should only contain letters and numbers');
            return;
        }

        try {
            const response = await axios.post(`${API_URL}/login`,
                { username: safeUsername, password: safePassword },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Key': process.env.REACT_APP_KEY,
                    }
                }
            );
            const token = response.data.token;
            localStorage.setItem('token', token);

            // Fetch profile details (image and name) immediately after login
            try {
                const profileResponse = await axios.get(`${API_URL}/get-profile-details`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'X-Key': process.env.REACT_APP_KEY,
                    }
                });

                const { imageUrl, fullName } = profileResponse.data;

                if (imageUrl) {
                    localStorage.setItem('profileImageUrl', imageUrl);
                }

                if (fullName) {
                    localStorage.setItem('fullName', fullName);
                }
            } catch (profileError) {
                console.error('Error fetching profile details:', profileError);
            }

            window.location.replace(location.state?.from || '/');
        } catch (error) {
            setError(error.response?.data?.error || 'An error occurred during login');
        }
    };

    return (
        <div className="ls-login-container">
            <div className="ls-login-form-container">
                <div className="ls-form-box">
                    <div className="ls-logo-container">
                        <Link to="/" className="ls-logo-link">
                            <img className="ls-favicon" src="/images/fav-da.png" alt="Favicon" />
                            {/* <img className="ls-logo" src="/images/devsaura2.png" alt="Logo" /> */}
                        </Link>
                    </div>
                    <h2 className="ls-form-title">Login</h2>
                    {showImageGenerationReason && (
                        <p className="ls-image-generation-reason">
                            Your daily quota of 50 images has ended. Don't worry - it's still free, you just have to login to continue generating or come back tomorrow.
                        </p>
                    )}
                    <form onSubmit={handleLogin} className="ls-login-form">
                        {error && <div className="ls-error-message">{error}</div>}
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                            required
                            className="ls-form-input"
                        />
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                            className="ls-form-input"
                        />
                        <button
                            type="submit"
                            className="ls-submit-button"
                        >
                            Log In
                        </button>
                    </form>
                    <p className="ls-signup-link-text">
                        Don't have an account yet?{' '}
                        <Link to="/signup" className="ls-signup-link">
                            Sign Up
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export { Signup, Login };
