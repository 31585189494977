import React from 'react';
import ToolContent from '../ToolContent';

const PetNameGenerator = () => {
  const formFields = [
    {
      name: 'petType',
      type: 'select',
      placeholder: 'Select Pet Type:',
      options: [
        { value: 'dog', label: 'Dog' },
        { value: 'cat', label: 'Cat' },
        { value: 'bird', label: 'Bird' },
        { value: 'fish', label: 'Fish' },
        { value: 'reptile', label: 'Reptile' },
        { value: 'other', label: 'Other' },
      ],
      required: true
    },
    {
      name: 'petGender',
      type: 'select',
      placeholder: 'Select Pet Gender:',
      options: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'neutral', label: 'Neutral' },
      ],
      required: true
    },
    { name: 'personalityTraits', type: 'textarea', placeholder: 'Personality traits of the pet:' },
    {
      name: 'preferredNameStyle',
      type: 'select',
      placeholder: 'Select Preferred Name Style:',
      options: [
        { value: 'traditional', label: 'Traditional' },
        { value: 'modern', label: 'Modern' },
        { value: 'unique', label: 'Unique' },
        { value: 'funny', label: 'Funny' },
        { value: 'classic', label: 'Classic' },
      ],
      required: true
    },
    { name: 'colorAppearance', type: 'text', placeholder: 'Color or appearance features:' },
    {
      name: 'nameLength',
      type: 'select',
      placeholder: 'Select Name Length:',
      options: [
        { value: 'short', label: 'Short' },
        { value: 'medium', label: 'Medium' },
        { value: 'long', label: 'Long' },
      ],
      required: true
    },
    { name: 'culturalPreferences', type: 'text', placeholder: 'Cultural or language preferences:' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_PET_NAME_GENERATOR}
      title="Pet Name Generator"
      imageName="pet_name"
    />
  );
};

export default PetNameGenerator;