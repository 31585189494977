import React, { useMemo, useEffect, useState, useCallback  } from 'react';
import { Link } from 'react-router-dom';
import '../css/Tools.css'

// Utility function to highlight search term
const highlightText = (text, searchTerm) => {
    if (!searchTerm.trim()) return text;

    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return text.split(regex).map((part, index) =>
        regex.test(part) ? <mark key={index} className="tools-highlight">{part}</mark> : part
    );
};

const Tools = () => {

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = useCallback((e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
    }, []);

    useEffect(() => {
    }, [searchTerm]);

    // Define tools data using useMemo
    const toolsData = useMemo(() => [
        {
            link: "/blog-generator",
            imgPath: "/images/tools/blog.jpg",
            title: "Write a Blog",
            description: "Effortlessly create high-quality blog posts with our Blog Generator tool. Simply provide a topic, tone, and keywords, and let our tool craft a compelling, well-structured blog for you. Perfect for busy content creators and marketers looking to streamline their writing process."
        },
        {
            link: "/generate-recipe",
            imgPath: "/images/tools/recipe.jpg",
            title: "Find a Recipe",
            description: "Discover delicious recipes tailored to your ingredients, preferences, and dietary needs. Our Recipe Finder tool helps you quickly locate the perfect dish, whether you're looking for a quick meal or a gourmet feast. Ideal for home cooks and food enthusiasts seeking culinary inspiration."
        },
        {
            link: "/prepare-interview",
            imgPath: "/images/tools/interview.jpg",
            title: "Prepare Interview",
            description: "Get ready for your interview with tailored questions and expert tips. Specify your job title and key focus areas to receive relevant content for any interview type. Ideal for job seekers looking to boost their confidence and performance."
        },
        {
            link: "/diet-planner",
            imgPath: "/images/tools/diet.jpg",
            title: "Plan Your Diet",
            description: "Create a personalized diet plan tailored to your fitness goals and dietary preferences. Provide details such as your age, weight, fitness goals, and local food availability to get a customized plan that fits your needs. Ideal for anyone looking to optimize their nutrition and achieve specific health goals."
        },
        {
            link: "/generate-image",
            imgPath: "/images/tools/image-generation.jpeg",
            title: "Generate Image",
            description: "Create unlimited custom images from text prompts with our tool. Specify parameters such as width and height and select from styles to generate unique visuals. This tool is perfect for designers, artists, and anyone who wants to visualize their ideas with precision and creativity."
        },
        {
            link: "/workout-planner",
            imgPath: "/images/tools/workout.jpg",
            title: "Plan Gym Workout",
            description: "Create a personalized workout plan tailored to your fitness goals and available equipment. Provide details such as your experience level, fitness goals, and preferred exercise types to receive a customized plan that fits your needs. Ideal for anyone looking to enhance their fitness routine and achieve specific training objectives."
        },
        {
            link: "/assignment-maker",
            imgPath: "/images/tools/assignment.jpg",
            title: "Complete an Assignment",
            description: "Receive comprehensive answers to your assignment questions based on your specific requirements. Input the assignment questions, desired answer length, English level, and any special instructions to get detailed responses tailored to your needs. Ideal for students, educators, and professionals looking to get well-crafted answers for various types of assignments."
        },
        {
            link: "/startup-name-generator",
            imgPath: "/images/tools/startup-name.jpg",
            title: "Look for Startup Names",
            description: "Generate creative and catchy startup name ideas based on industry, keywords, and your vision. Whether you're launching a tech company or a local business, this tool helps you find the perfect brand name."
        },
        {
            link: "/domain-name-ideas",
            imgPath: "/images/tools/domain-name.jpg",
            title: "Domain Name Ideas",
            description: "Find the ideal domain name for your website or business with our Domain Name Ideas tool. Input your business niche or desired keywords, and get a list of available domain names to kickstart your online presence."
        },
        {
            link: "/nickname-finder",
            imgPath: "/images/tools/nickname.jpg",
            title: "Find Nick Names",
            description: "Discover fun and personalized nicknames with our Nick Name Finder. Whether it's for friends, characters, or online personas, enter a name or personality traits to generate creative nickname ideas."
        },
        {
            link: "/baby-name-suggestions",
            imgPath: "/images/tools/baby-names.jpg",
            title: "Baby Name Suggestions",
            description: "Struggling to choose the perfect baby name? Our Baby Name Suggestions tool offers unique and meaningful name ideas based on cultural preferences, trends, or family traditions, helping you find the right name for your little one."
        },
        {
            link: "/gift-suggestions",
            imgPath: "/images/tools/gift-suggestions.jpg",
            title: "Gift Suggestions",
            description: "Stuck on what to gift someone? Use our Gift Suggestions Genie to find the perfect present based on the recipient's preferences, occasion, and budget. Make every gift memorable and thoughtful with our personalized ideas."
        },
        {
            link: "/dream-analyzer",
            imgPath: "/images/tools/dream-analyzer.jpg",
            title: "Analyze Your Dreams",
            description: "Decode the meaning behind your dreams with our Dream Analyzer. Input your dream details, and receive insightful interpretations that blend psychology and creativity. Share your results with friends and see what your dreams reveal."
        },
        {
            link: "/hobby-finder",
            imgPath: "/images/tools/hobby-finder.jpg",
            title: "Find Hobbies For You",
            description: "Discover new and exciting hobbies with our Hobby Finder tool. Simply input your interests, time availability, and preferences, and explore personalized hobby suggestions that you may never have thought of before."
        },
        {
            link: "/pet-name-ideas",
            imgPath: "/images/tools/pet-names.jpg",
            title: "Pet Name Ideas",
            description: "Find the perfect name for your new furry friend with our Pet Name Ideas tool. Whether you have a dog, cat, bird, or any other pet, enter their traits or breed to receive unique and fun name suggestions."
        },
        {
            link: "/startup-ideas",
            imgPath: "/images/tools/startup-ideas.jpg",
            title: "Startup Ideas",
            description: "Looking to launch a new business? Use our Startup Ideas tool to get innovative business concepts tailored to your interests and industry trends. Whether you're looking for tech solutions or local ventures, find the right idea to kickstart your entrepreneurial journey."
        }
    ], []);

    // Filter tools based on search term using useMemo
    const filteredTools = useMemo(() => {
        if (!searchTerm.trim()) {
            return toolsData;
        }
        return toolsData.filter(tool =>
            tool.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            tool.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, toolsData]);

    return (
        <div>
            <div className='tools-tools-heading'>
                <h1 className='tools-tools-title'>All tools</h1>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search tools..."
                    className="tools-tools-search"
                />
            </div>
            <div className="tools-tools-container">
                <Link to="/generate-image" className="tools-featured-tool">
                    <img src="/images/tools/image-generation.jpeg" alt="generate_image" className="tools-featured-image" />
                    <div className="tools-featured-overlay">
                        <h3 className="tools-featured-title">Generate Image</h3>
                    </div>
                    <span className="tools-featured-tag">with ai prompt improvement</span>
                </Link>
                <div className="tools-tools-grid">
                    {filteredTools.map((tool, index) => (
                        <Link to={tool.link} key={index} className="tools-tool-card">
                            <img src={tool.imgPath} alt={tool.title} className="tools-tool-image" />
                            <div className="tools-tool-content">
                                <h3 className="tools-tool-title">{highlightText(tool.title, searchTerm)}</h3>
                                <span className="tools-tool-subtitle">with related ai visuals</span>
                            </div>
                        </Link>
                    ))}
                </div>
                {filteredTools.length === 0 && <p className="tools-no-results">No tools found matching your search.</p>}
            </div>
        </div>
    );
}

export default Tools;
