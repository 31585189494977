import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import { Download, Clipboard, Loader, Check, Heart } from 'lucide-react';
import '../css/Home.css'

const Home = () => {
  const [feedImages, setFeedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const loader = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const canvasRefs = useRef({});
  const profileCanvasRefs = useRef({});
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [buttonStates, setButtonStates] = useState({});
  const [likeInProgress, setLikeInProgress] = useState({});

  const handleImageClick = (index) => {
    if (window.innerWidth <= 768) {
      setSelectedImageIndex(prevIndex => prevIndex === index ? null : index);
    }
  };


  const handleLike = async (imageId, index) => {
    if (!imageId || likeInProgress[imageId]) return;

    setLikeInProgress(prev => ({ ...prev, [imageId]: true }));

    const currentImage = feedImages[index];
    const currentLikeCount = currentImage.likeCount;
    const isLiked = currentImage.isLiked;

    try {
      // Optimistically update UI
      setFeedImages(prevImages => prevImages.map((img, idx) =>
        idx === index ? { ...img, likeCount: isLiked ? currentLikeCount - 1 : currentLikeCount + 1, isLiked: !isLiked } : img
      ));

      const response = await authenticatedApiCall(`/update-like-count/${imageId}`, 'POST', {
        increment: isLiked ? -1 : 1
      });

      if (!response.success) {
        throw new Error(response.error || 'Failed to update like count');
      }
    } catch (err) {
      console.error('Error updating like count:', err);
      setError(`Error updating like: ${err.message}`);

      // Revert the optimistic update
      setFeedImages(prevImages => prevImages.map((img, idx) =>
        idx === index ? { ...img, likeCount: currentLikeCount, isLiked: isLiked } : img
      ));
    } finally {
      setLikeInProgress(prev => ({ ...prev, [imageId]: false }));
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.home-image-card')) {
        setSelectedImageIndex(null);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const fetchImages = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await authenticatedApiCall(`/get-random-images?page=${page}`, 'GET');
      setFeedImages(prevImages => [...prevImages, ...response]);
      setPage(prevPage => prevPage + 1);
    } catch (err) {
      console.error('Error fetching images:', err);
      setError('Error fetching images: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      fetchImages();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [location.pathname]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
    return () => {
      // eslint-disable-next-line
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [handleObserver]);

  useEffect(() => {
    fetchImages();
    // eslint-disable-next-line
  }, []);

  const drawImageOnCanvas = (imageUrl, canvas, isProfilePic = false) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;

      if (isProfilePic) {
        ctx.beginPath();
        ctx.arc(img.width / 2, img.height / 2, img.width / 2, 0, Math.PI * 2);
        ctx.closePath();
        ctx.clip();
      }

      ctx.drawImage(img, 0, 0, img.width, img.height);
    };
    img.src = imageUrl;
  };
  const handleDownload = async (imageUrl, id) => {
    setButtonStates(prev => ({ ...prev, [id]: { download: 'loading' } }));
    try {
      const img = new Image();
      img.crossOrigin = "Anonymous";

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = imageUrl;
      });

      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(img, 0, 0, img.width, img.height);

      ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
      ctx.font = '18px Arial';
      ctx.textAlign = 'right';
      ctx.textBaseline = 'bottom';
      ctx.fillText('ai.devsaura.com', canvas.width - 15, canvas.height - 15);

      const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
      const url = window.URL.createObjectURL(blob);

      const extractFilename = (url) => {
        const prompt = extractPromptFromUrl(url);
        const words = prompt.trim().split(/\s+/);
        const filenameBase = words.slice(0, 8).join('_') || 'image';
        const safeBase = filenameBase.length > 45 ? filenameBase.slice(0, 45) : filenameBase;
        const sanitizedBase = safeBase.replace(/[<>:"/\\|?*]/g, '_');
        return `${sanitizedBase}.jpg`;
      };

      const filename = extractFilename(imageUrl);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      // Set button state to success
      setButtonStates(prev => ({ ...prev, [id]: { download: 'success' } }));
      setTimeout(() => {
        setButtonStates(prev => ({ ...prev, [id]: { download: 'default' } }));
      }, 2000);
    } catch (error) {
      console.error('Download error:', error);
      setError("An error occurred while downloading the image.");
      setButtonStates(prev => ({ ...prev, [id]: { download: 'default' } }));
    }
  };

  const extractPromptFromUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const promptPath = urlObj.pathname.split('/prompt/')[1];
      return decodeURIComponent(promptPath);
    } catch (error) {
      console.error('Error extracting prompt from URL:', error);
      return '';
    }
  };

  const copyPromptToClipboard = (imageUrl, id) => {
    // Set button state to loading
    setButtonStates(prev => ({ ...prev, [id]: { copy: 'loading' } }));
    const prompt = extractPromptFromUrl(imageUrl);
    navigator.clipboard.writeText(prompt).then(() => {
      // Set button state to success
      setButtonStates(prev => ({ ...prev, [id]: { copy: 'success' } }));
      setTimeout(() => {
        setButtonStates(prev => ({ ...prev, [id]: { copy: 'default' } }));
      }, 2000);
      setError('Prompt copied to clipboard!');
      setTimeout(() => setError(null), 3000);
    }).catch(err => {
      console.error('Failed to copy prompt: ', err);
      setButtonStates(prev => ({ ...prev, [id]: { copy: 'default' } }));
    });
  };

  return (
    <div className="home-home-container margin margin-home">
      <div className="home-feed-container">
        {feedImages.map((image, index) => (
          <div
            key={`${image._id.$oid}-${index}`}
            className={`home-image-card ${selectedImageIndex === index ? 'selected' : ''}`}
            onClick={() => handleImageClick(index)}
          >
            <div className="home-image-wrapper">
              <canvas
                ref={el => {
                  if (el) {
                    canvasRefs.current[image._id?.$oid] = el;
                    drawImageOnCanvas(image.imageUrl, el);
                  }
                }}
                className="home-image-canvas"
              />
              <div className={`home-image-overlay ${selectedImageIndex === index ? 'visible' : ''}`}>
                <div className="home-image-details">
                  <div className="home-user-info">
                    <canvas
                      ref={el => {
                        if (el) {
                          profileCanvasRefs.current[image._id?.$oid] = el;
                          drawImageOnCanvas(image.userProfileImage, el, true);
                        }
                      }}
                      className="home-user-avatar"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/profile/${image.userName}`);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                    <Link to={`/profile/${image.userName}`} className="home-user-link" onClick={(e) => e.stopPropagation()}>
                      {image.userName}
                    </Link>
                  </div>
                  <div className="home-image-buttons">
                    <button
                      className={`home-action-button home-image-likes ${image.isLiked ? 'liked' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLike(image._id.$oid, index);
                      }}
                      disabled={likeInProgress[image._id.$oid]}
                    >
                      <Heart
                        size={20}
                        fill={image.isLiked ? 'red' : 'none'}
                        color={image.isLiked ? 'red' : '#fff'}
                      />
                      {image.likeCount}                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        copyPromptToClipboard(image.imageUrl, image._id?.$oid);
                      }}
                      className="home-action-button"
                      title="Copy Prompt"
                      // Add disabled attribute
                      disabled={buttonStates[image._id?.$oid]?.copy === 'loading'}
                    >
                      {buttonStates[image._id?.$oid]?.copy === 'loading' ? (
                        <Loader size={20} className="home-action-icon animate-spin" />
                      ) : buttonStates[image._id?.$oid]?.copy === 'success' ? (
                        <Check size={20} className="home-action-icon" />
                      ) : (
                        <Clipboard size={20} className="home-action-icon" />
                      )}
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownload(image.imageUrl, image._id?.$oid);
                      }}
                      className="home-action-button"
                      title="Download"
                      // Add disabled attribute
                      disabled={buttonStates[image._id?.$oid]?.download === 'loading'}
                    >
                      {buttonStates[image._id?.$oid]?.download === 'loading' ? (
                        <Loader size={20} className="home-action-icon animate-spin" />
                      ) : buttonStates[image._id?.$oid]?.download === 'success' ? (
                        <Check size={20} className="home-action-icon" />
                      ) : (
                        <Download size={20} className="home-action-icon" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {loading && (
          <p className="home-loading-text">Adding new ideas...</p>
        )}
        {error && (
          // <p className="home-error-text">{error}</p>
          <p className="home-error-text">Loading failed!</p>
        )}
        <div ref={loader} />
      </div>
    </div>
  );
};

export default Home;