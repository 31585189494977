import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import { Search, RefreshCw, Download, Clipboard, Loader, Check, Heart } from 'lucide-react';
import '../../css/Explore.css';

const UserCard = ({ user, onProfileClick }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (user.profile_image_url) {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        const canvas = canvasRef.current;
        if (canvas) {
          canvas.width = 100;
          canvas.height = 100;
          const ctx = canvas.getContext('2d');
          ctx.beginPath();
          ctx.arc(50, 50, 50, 0, Math.PI * 2);
          ctx.closePath();
          ctx.clip();
          ctx.drawImage(img, 0, 0, 100, 100);
        }
      };
      img.src = user.profile_image_url;
    }
  }, [user.profile_image_url]);

  return (
    <div className="explore-user-card" onClick={() => onProfileClick(user.username)}>
      <canvas ref={canvasRef} width="70" height="70" className="explore-user-avatar" />
      <div className="explore-user-card-names">
        <h3 className="explore-user-name">{user.name}</h3>
        <p className="explore-user-username">@{user.username}</p>
      </div>
    </div>
  );
};

const Explore = () => {
  const [users, setUsers] = useState([]);
  const [images, setImages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [buttonStates, setButtonStates] = useState({});
  const [likeInProgress, setLikeInProgress] = useState({});
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const canvasRefs = useRef({});

  const navigate = useNavigate();

  const fetchRandomUsers = async () => {
    try {
      const response = await authenticatedApiCall('/get-random-users', 'GET');
      setUsers(response);
      setImages([]);
      setError(null);
    } catch (error) {
      setError('Not Connected!');
      setTimeout(() => setError(null), 2000);
    }
  };

  useEffect(() => {
    fetchRandomUsers();
  }, []);

  const handleSearch = async () => {
    if (!searchTerm.trim()) {
      fetchRandomUsers();
      return;
    }

    try {
      const response = await authenticatedApiCall(`/search?query=${searchTerm}`, 'GET');
      setUsers(response.users);
      console.log(response);
      setImages(response.images);
      setError(null);
    } catch (error) {
      setError('Search failed');
      setUsers([]);
      setImages([]);
      setTimeout(() => setError(null), 4000);
    }
  };

  const handleProfileClick = (username) => {
    navigate(`/profile/${username}`);
  };

  const handleImageClick = (index) => {
    if (window.innerWidth <= 768) {
      setSelectedImageIndex(prevIndex => prevIndex === index ? null : index);
    }
  };

  const handleLike = async (imageId, index) => {
    if (!imageId || likeInProgress[imageId]) return;

    setLikeInProgress(prev => ({ ...prev, [imageId]: true }));

    const currentImage = images[index];
    const currentLikeCount = currentImage.likeCount;
    const isLiked = currentImage.isLiked;

    try {
      // Optimistically update UI
      setImages(prevImages => prevImages.map((img, idx) =>
        idx === index ? { ...img, likeCount: isLiked ? currentLikeCount - 1 : currentLikeCount + 1, isLiked: !isLiked } : img
      ));

      const response = await authenticatedApiCall(`/update-like-count/${imageId}`, 'POST', {
        increment: isLiked ? -1 : 1
      });

      if (!response.success) {
        throw new Error(response.error || 'Failed to update like count');
      }
    } catch (err) {
      console.error('Error updating like count:', err);
      setError(`Error updating like: ${err.message}`);

      // Revert the optimistic update
      setImages(prevImages => prevImages.map((img, idx) =>
        idx === index ? { ...img, likeCount: currentLikeCount, isLiked: isLiked } : img
      ));
    } finally {
      setLikeInProgress(prev => ({ ...prev, [imageId]: false }));
    }
  };

  const handleDownload = async (imageUrl, id) => {
    setButtonStates(prev => ({ ...prev, [id]: { download: 'loading' } }));
    try {
      const img = new Image();
      img.crossOrigin = "Anonymous";

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = imageUrl;
      });

      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(img, 0, 0, img.width, img.height);

      ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
      ctx.font = '18px Arial';
      ctx.textAlign = 'right';
      ctx.textBaseline = 'bottom';
      ctx.fillText('ai.devsaura.com', canvas.width - 15, canvas.height - 15);

      const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
      const url = window.URL.createObjectURL(blob);

      const extractFilename = (url) => {
        const prompt = extractPromptFromUrl(url);
        const words = prompt.trim().split(/\s+/);
        const filenameBase = words.slice(0, 8).join('_') || 'image';
        const safeBase = filenameBase.length > 45 ? filenameBase.slice(0, 45) : filenameBase;
        const sanitizedBase = safeBase.replace(/[<>:"/\\|?*]/g, '_');
        return `${sanitizedBase}.jpg`;
      };

      const filename = extractFilename(imageUrl);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      setButtonStates(prev => ({ ...prev, [id]: { download: 'success' } }));
      setTimeout(() => {
        setButtonStates(prev => ({ ...prev, [id]: { download: 'default' } }));
      }, 2000);
    } catch (error) {
      console.error('Download error:', error);
      setError("An error occurred while downloading the image.");
      setButtonStates(prev => ({ ...prev, [id]: { download: 'default' } }));
    }
  };

  const extractPromptFromUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const promptPath = urlObj.pathname.split('/prompt/')[1];
      return decodeURIComponent(promptPath);
    } catch (error) {
      console.error('Error extracting prompt from URL:', error);
      return '';
    }
  };

  const copyPromptToClipboard = (imageUrl, id) => {
    setButtonStates(prev => ({ ...prev, [id]: { copy: 'loading' } }));
    const prompt = extractPromptFromUrl(imageUrl);
    navigator.clipboard.writeText(prompt).then(() => {
      setButtonStates(prev => ({ ...prev, [id]: { copy: 'success' } }));
      setTimeout(() => {
        setButtonStates(prev => ({ ...prev, [id]: { copy: 'default' } }));
      }, 2000);
      setError('Prompt copied to clipboard!');
      setTimeout(() => setError(null), 3000);
    }).catch(err => {
      console.error('Failed to copy prompt: ', err);
      setButtonStates(prev => ({ ...prev, [id]: { copy: 'default' } }));
    });
  };

  const drawImageOnCanvas = (imageUrl, canvas) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
    };
    img.src = imageUrl;
  };

  return (
    <div className="explore-explore-container margin">
      <h1 className="explore-explore-title">Explore Artists and Images!</h1>
      <div className="explore-search-container">
        <div className="explore-search-input-container">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search users or images"
            className="explore-search-input"
          />
        </div>
        <button onClick={handleSearch} className="explore-search-button">
          <Search size={20} />
        </button>
        <button onClick={fetchRandomUsers} className="explore-refresh-button">
          <RefreshCw size={19} />
        </button>
      </div>
      {error && <div className="explore-error-message">{error}</div>}
      {users.length > 0 && (
        <div>
          <h2 className='explore-users-heading'>Users</h2>
          <div className="explore-user-grid">
            {users.map((user, index) => (
              <UserCard key={index} user={user} onProfileClick={handleProfileClick} />
            ))}
          </div>
        </div>
      )}
      {images.length > 0 && (
        <div>
          <h2 className='explore-images-heading'>Images</h2>
          <div className="explore-image-grid">
            {images.map((image, index) => (
              <div
                key={`${image.id}-${index}`}
                className={`explore-image-card ${selectedImageIndex === index ? 'selected' : ''}`}
                onClick={() => handleImageClick(index)}
              >
                <div className="explore-image-wrapper">
                  <canvas
                    ref={el => {
                      if (el) {
                        canvasRefs.current[image.id] = el;
                        drawImageOnCanvas(image.imageUrl, el);
                      }
                    }}
                    className="explore-image-canvas"
                  />
                  <div className={`explore-image-overlay ${selectedImageIndex === index ? 'visible' : ''}`}>
                    <div className="explore-image-buttons">
                      <button
                        className={`explore-action-button explore-image-likes ${image.isLiked ? 'liked' : ''}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleLike(image.id, index);
                        }}
                        disabled={likeInProgress[image.id]}
                      >
                        <Heart
                          size={20}
                          fill={image.isLiked ? 'red' : 'none'}
                          color={image.isLiked ? 'red' : '#fff'}
                        />
                        {image.likeCount}
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          copyPromptToClipboard(image.imageUrl, image._id);
                        }}
                        className="explore-action-button"
                        title="Copy Prompt"
                        disabled={buttonStates[image._id]?.copy === 'loading'}
                      >
                        {buttonStates[image._id]?.copy === 'loading' ? (
                          <Loader size={20} className="explore-action-icon animate-spin" />
                        ) : buttonStates[image._id]?.copy === 'success' ? (
                          <Check size={20} className="explore-action-icon" />
                        ) : (
                          <Clipboard size={20} className="explore-action-icon" />
                        )}
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownload(image.imageUrl, image._id);
                        }}
                        className="explore-action-button"
                        title="Download"
                        disabled={buttonStates[image._id]?.download === 'loading'}
                      >
                        {buttonStates[image._id]?.download === 'loading' ? (
                          <Loader size={20} className="explore-action-icon animate-spin" />
                        ) : buttonStates[image._id]?.download === 'success' ? (
                          <Check size={20} className="explore-action-icon" />
                        ) : (
                          <Download size={20} className="explore-action-icon" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Explore;