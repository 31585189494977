import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import { Home, Search, User, BrainCircuit } from 'lucide-react';
import '../css/Header.css'

const Header = () => {
  const isLoggedIn = !!localStorage.getItem('token');
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const canvasRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const setViewHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    const updateNavHeight = () => {
      const navHeight = window.innerHeight - window.visualViewport.height + 60;
      document.documentElement.style.setProperty('--mobile-nav-height', `${navHeight}px`);
    };

    setViewHeight();
    updateNavHeight();

    window.addEventListener('resize', setViewHeight);
    window.visualViewport.addEventListener('resize', updateNavHeight);

    return () => {
      window.removeEventListener('resize', setViewHeight);
      window.visualViewport.removeEventListener('resize', updateNavHeight);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      fetchProfileDetails();
    }
  }, [isLoggedIn]);

  const fetchProfileDetails = async () => {
    try {
      const response = await authenticatedApiCall('/get-profile-details', 'GET');
      if (response.imageUrl) {
        setProfileImageUrl(response.imageUrl);
      }
      if (response.fullName) {
        localStorage.setItem('fullName', response.fullName);
      }
    } catch (error) {
      console.error('Error fetching profile details:', error);
    }
  };

  useEffect(() => {
    if (profileImageUrl && canvasRef.current) {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        const canvas = canvasRef.current;
        canvas.width = 45;
        canvas.height = 45;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, 45, 45);
      };
      img.src = profileImageUrl;
    }
  }, [profileImageUrl]);

  const NavItem = ({ to, icon: Icon, label }) => {
    const isActive = location.pathname === to;
    return (
      <Link 
        to={to} 
        className={`header-nav-item ${isActive ? 'active' : ''}`}
      >
        <Icon size={24} />
        <span className="header-nav-label">{label}</span>
      </Link>
    );
  };

  return (
    <>
      <nav className="header-header-nav">
        <div className="header-nav-container">
          <div className="header-nav-items">
            <NavItem to="/home" icon={Home} label="Home" />
            <NavItem to="/explore" icon={Search} label="Explore" />
            <NavItem to="/create" icon={BrainCircuit} label="Create" />
            {isLoggedIn ? (
              <NavItem to="/profile" icon={User} label="Profile" />
            ) : (
              <NavItem to="/login" icon={User} label="Login" />
            )}
          </div>
        </div>
      </nav>
      <div className="header-header-spacer"></div>
    </>
  );
}

export default Header;