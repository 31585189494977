import React from 'react';
import ToolContent from '../ToolContent';

const NicknameGenerator = () => {
  const formFields = [
    {
      name: 'nameType',
      type: 'select',
      placeholder: 'Select Name Type:',
      options: [
        { value: 'playful', label: 'Playful' },
        { value: 'professional', label: 'Professional' },
        { value: 'fantasy', label: 'Fantasy' },
        { value: 'serious', label: 'Serious' },
        { value: 'cool', label: 'Cool' },
      ],
      required: true
    },
    {
      name: 'genderPreference',
      type: 'select',
      placeholder: 'Select Gender Preference:',
      options: [
        { value: '', label: 'Any' },
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
      ],
    },
    {
      name: 'length',
      type: 'select',
      placeholder: 'Select Length:',
      options: [
        { value: 'short', label: 'Short' },
        { value: 'medium', label: 'Medium' },
        { value: 'long', label: 'Long' },
      ],
      required: true
    },
    { name: 'keywords', type: 'text', placeholder: 'Keywords or Themes:' },
    { name: 'personalityTraits', type: 'text', placeholder: 'Personality Traits:' },
    { name: 'avoidedWords', type: 'text', placeholder: 'Avoided Words/Phrases:' },
    { name: 'culturalReferences', type: 'text', placeholder: 'Cultural References (if any):' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_NICKNAME_GENERATOR}
      title="Nickname Generator"
      imageName="nickname"
    />
  );
};

export default NicknameGenerator;