import React from 'react';
import ToolContent from '../ToolContent';

const HobbyFinder = () => {
  const formFields = [
    { name: 'interests', type: 'textarea', placeholder: 'Interests or passions:', required: true },
    { name: 'skills', type: 'textarea', placeholder: 'Skills or talents you have or want to develop:' },
    { name: 'timeAvailability', type: 'number', placeholder: 'Time available per week (hours):' },
    { name: 'budget', type: 'text', placeholder: 'Budget for hobbies:' },
    {
      name: 'socialPreferences',
      type: 'select',
      placeholder: 'Select Social Preference:',
      options: [
        { value: 'solo', label: 'Solo' },
        { value: 'social', label: 'Social' },
        { value: 'both', label: 'Both' },
      ],
      required: true
    },
    {
      name: 'physicalActivity',
      type: 'select',
      placeholder: 'Select Physical Activity Level:',
      options: [
        { value: 'active', label: 'Active' },
        { value: 'sedentary', label: 'Sedentary' },
        { value: 'any', label: 'Any' },
      ],
      required: true
    },
    { name: 'learningGoals', type: 'textarea', placeholder: 'Learning goals or objectives:' },
    { name: 'lifestyle', type: 'textarea', placeholder: 'Lifestyle considerations or constraints:' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_HOBBY_FINDER}
      title="Hobby Finder"
      imageName="hobby_image"
    />
  );
};

export default HobbyFinder;