import React from 'react';
import ToolContent from '../ToolContent';

const StartupIdeaGenerator = () => {
  const formFields = [
    { name: 'industry', type: 'text', placeholder: 'Industry/Sector:', required: true },
    { name: 'targetMarket', type: 'text', placeholder: 'Target Market/Audience:', required: true },
    { name: 'problemToSolve', type: 'textarea', placeholder: 'Problem to Solve:', required: true },
    { name: 'solutionApproach', type: 'textarea', placeholder: 'Solution Approach:', required: true },
    {
      name: 'innovationLevel',
      type: 'select',
      placeholder: 'Select Innovation Level:',
      options: [
        { value: 'disruptive', label: 'Disruptive' },
        { value: 'incremental', label: 'Incremental' },
        { value: 'sustaining', label: 'Sustaining' },
      ],
      required: true
    },
    { name: 'budgetRange', type: 'text', placeholder: 'Budget Range (e.g., low, medium, high):' },
    {
      name: 'teamSize',
      type: 'select',
      placeholder: 'Select Team Size:',
      options: [
        { value: 'small', label: 'Small' },
        { value: 'medium', label: 'Medium' },
        { value: 'large', label: 'Large' },
      ],
      required: true
    },
    { name: 'geographicFocus', type: 'text', placeholder: 'Geographic Focus (e.g., local, national, international):' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_STARTUP_IDEA_GENERATOR}
      title="Startup Idea Generator"
      imageName="startup_idea"
    />
  );
};

export default StartupIdeaGenerator;