import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import { incrementImageGenerationCount, isImageGenerationLimited, isLoggedIn } from '../../utils/cookies';
import '../../css/ImageGenerator.css'

const MessageDisplay = ({ message, type }) => {
    if (!message) return null;

    return (
        <div className={`imgen-message-display ${type}`}>
            {message}
        </div>
    );
};


const ImageGenerator = () => {
    const [formData, setFormData] = useState({
        prompt: '',
        width: 1080,
        height: 2400,
        artStyle: '',
        perspective: '',
        theme: '',
        lighting: '',
        colorPalette: '',
        composition: '',
        emotionalTone: ''
    });

    const [generatedImageUrl, setGeneratedImageUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({ text: null, type: null });
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(null);
    const [wordCount, setWordCount] = useState(0);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [mobileView, setMobileView] = useState('prompt');
    const [canvasError, setCanvasError] = useState(null);
    const desktopCanvasRef = useRef(null);
    const mobileCanvasRef = useRef(null);
    const [isImageLoading, setIsImageLoading] = useState(false);

    const clearMessageAfterDelay = () => {
        setTimeout(() => {
            setMessage({ text: null, type: null });
        }, 3000);
    };

    const displayImageOnCanvas = useCallback((imageUrl) => {
        const desktopCanvas = desktopCanvasRef.current;
        const mobileCanvas = mobileCanvasRef.current;

        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            setImageDimensions({ width: img.width, height: img.height });

            const drawOnCanvas = (canvas) => {
                if (canvas) {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                }
            };

            drawOnCanvas(desktopCanvas);
            drawOnCanvas(mobileCanvas);

            setIsImageLoading(false); // Stop loading spinner when image is drawn
        };
        img.onerror = () => {
            setCanvasError("Failed to load the image. Please try again.");
            setIsImageLoading(false); // Stop loading spinner on error
        };
        img.src = imageUrl;
    }, []);

    useEffect(() => {
        if (generatedImageUrl) {
            displayImageOnCanvas(generatedImageUrl);
        }
    }, [generatedImageUrl, displayImageOnCanvas]);

    const handleSetAsProfile = async () => {
        if (!isLoggedIn()) {
            navigate('/login', { state: { from: '/generate-image' } });
            return;
        }

        try {
            const response = await authenticatedApiCall(
                `/set-profile-image`,
                'POST',
                { imageUrl: generatedImageUrl }
            );

            if (response.success) {
                setMessage({ text: 'Profile image updated successfully.', type: 'success' });
                clearMessageAfterDelay();
            }
        } catch (error) {
            console.error('Error setting profile image:', error);
            setMessage({ text: 'Failed to set profile image. Please try again.', type: 'error' });
            clearMessageAfterDelay();
        }
    };

    useEffect(() => {
        if (isImageGenerationLimited()) {
            navigate('/login', { state: { from: '/generate-image' } });
        }
    }, [navigate]);

    const handleSaveToFolder = async () => {
        if (!isLoggedIn()) {
            navigate('/login', { state: { from: '/generate-image' } });
            return;
        }

        try {
            const response = await authenticatedApiCall(
                '/save-image-to-folder',
                'POST',
                { imageUrl: generatedImageUrl }
            );

            if (response.success) {
                setMessage({ text: "Image saved successfully!", type: 'success' });
            } else {
                setMessage({ text: response.error || "Failed to save image. Please try again.", type: 'error' });
            }
        } catch (error) {
            setMessage({ text: "Maximum 30 images can be saved for now!", type: 'error' });
        }

        clearMessageAfterDelay();
    };

    const handleChange = useCallback((e) => {
        const { name, value, type } = e.target;

        setFormData(prevData => {
            let newValue = value;

            if (name === 'prompt') {
                const words = value.split(/\s+/);
                const truncatedWords = words.map(word => word.slice(0, 35));
                newValue = truncatedWords.join(' ');
                const newWordCount = truncatedWords.filter(word => word.length > 0).length;

                if (newWordCount > 300) {
                    newValue = truncatedWords.slice(0, 300).join(' ');
                    setMessage({ text: 'Prompt is limited to 300 words, each word up to 35 characters.', type: 'error' });
                    clearMessageAfterDelay();
                }

                setWordCount(Math.min(newWordCount, 300));
            } else if (name === 'width' || name === 'height') {
                newValue = Math.min(2800, Math.max(0, Number(value)));
            } else if (type === 'number') {
                newValue = Number(value);
            }

            return { ...prevData, [name]: newValue };
        });
    }, []);

    const handleImprovePrompt = async () => {
        if (!formData.prompt) {
            setMessage({ text: "Please enter a prompt to improve.", type: 'error' });
            clearMessageAfterDelay();
            return;
        }
        setMessage({ text: "Improving your prompt...", type: 'info' });
        try {
            const response = await authenticatedApiCall(
                '/improve-prompt',
                'POST',
                { prompt: formData.prompt }
            );
            if (response.improvedPrompt) {
                setFormData(prevData => ({
                    ...prevData,
                    prompt: response.improvedPrompt
                }));
                setWordCount(response.improvedPrompt.split(/\s+/).filter(word => word.length > 0).length);
                setMessage({ text: "Prompt improved successfully!", type: 'success' });
            }
        } catch (error) {
            setMessage({ text: "Failed to improve prompt. Please try again.", type: 'error' });
        }
        clearMessageAfterDelay();
    };

    const handleRandomPrompt = async () => {
        setMessage({ text: "Generating random prompt...", type: 'info' });
        try {
            const response = await authenticatedApiCall('/random-prompt', 'GET');
            if (response.randomPrompt) {
                setFormData(prevData => ({
                    ...prevData,
                    prompt: response.randomPrompt
                }));
                setWordCount(response.randomPrompt.split(/\s+/).filter(word => word.length > 0).length);
                setMessage({ text: "Random prompt generated successfully!", type: 'success' });
            }
        } catch (error) {
            setMessage({ text: "Failed to generate random prompt. Please try again.", type: 'error' });
        }
        clearMessageAfterDelay();
    };


    const handleSubmit = async (e) => {
        if (isImageGenerationLimited()) {
            navigate('/login', { state: { from: '/generate-image' } });
            return;
        }
        e.preventDefault();
        setIsLoading(true);
        setGeneratedImageUrl('');

        const {
            prompt,
            artStyle,
            perspective,
            theme,
            lighting,
            colorPalette,
            composition,
            emotionalTone,
            mood
        } = formData;

        const options = [
            artStyle && `Art Style: ${artStyle}`,
            perspective && `Perspective: ${perspective}`,
            theme && `Theme: ${theme}`,
            lighting && `Lighting: ${lighting}`,
            colorPalette && `Color Palette: ${colorPalette}`,
            composition && `Composition Focus: ${composition}`,
            emotionalTone && `Emotional Tone: ${emotionalTone}`,
            mood && `Mood: ${mood}`
        ].filter(Boolean).join(', ');

        const fullPrompt = options ? `${prompt}. ${options}` : prompt;

        try {
            const response = await authenticatedApiCall(
                process.env.REACT_APP_IMAGE,
                'POST',
                { ...formData, prompt: fullPrompt }
            );

            if (response.imageUrl) {
                setGeneratedImageUrl(response.imageUrl);
                if (!isLoggedIn()) {
                    incrementImageGenerationCount();
                }
                setMessage({ text: "Your stunning art is on its way.", type: 'info' });
                setIsImageLoading(true); // Start loading spinner after successful API call
                clearMessageAfterDelay();
            } else {
                throw new Error('No image in response');
            }
        } catch (error) {
            console.log("Error submitting")
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        if (generatedImageUrl) {
            const img = new Image();
            img.onload = () => {
                setImageDimensions({ width: img.width, height: img.height });
            };
            img.src = generatedImageUrl;
        }
    }, [generatedImageUrl]);

    const handleDownload = async () => {
        if (!isLoggedIn()) {
            startCountdown();
            return;
        }

        downloadImage();
    };

    const startCountdown = () => {
        setCountdown(5);
    };

    const downloadImage = async () => {
        try {
            const canvas = desktopCanvasRef.current || mobileCanvasRef.current;
            if (!canvas) return;

            // Add watermark
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
            ctx.font = '16px Arial';
            ctx.textAlign = 'right';
            ctx.textBaseline = 'bottom';
            ctx.fillText('ai.devsaura.com', canvas.width - 10, canvas.height - 10);

            const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
            const url = window.URL.createObjectURL(blob);

            const extractFilename = (prompt) => {
                const words = prompt.trim().split(/\s+/);
                const filenameBase = words.slice(0, 6).join('_') || 'image';
                const safeBase = filenameBase.length > 45 ? filenameBase.slice(0, 45) : filenameBase;
                const sanitizedBase = safeBase.replace(/[<>:"/\\|?*]/g, '_');
                return `${sanitizedBase}.jpg`;
            };

            const filename = extractFilename(formData.prompt);

            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);

            if (!isLoggedIn()) {
                setMessage({ text: "Log in to download images instantly.", type: 'success' });
                clearMessageAfterDelay();
            }
        } catch (error) {
            console.error('Download error:', error);
            setMessage({ text: "An error occurred while downloading the image.", type: 'error' });
            clearMessageAfterDelay();
        }
    };

    useEffect(() => {
        let timer;
        if (countdown !== null && countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            downloadImage();
        }
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [countdown]);

    const fieldOptions = {
        artStyle: ['realistic', 'abstract', 'surreal', 'cartoonish', 'minimalist'],
        perspective: ["bird's eye view", 'first-person', 'wide angle', 'close-up'],
        theme: ['futuristic', 'nature', 'urban', 'fantasy', 'historical'],
        lighting: ['soft lighting', 'dramatic lighting', 'natural lighting', 'neon lighting'],
        colorPalette: ['warm colors', 'cool colors', 'monochromatic', 'pastel colors'],
        composition: ['central subject', 'rule of thirds', 'leading lines', 'asymmetry'],
        emotionalTone: ['peaceful', 'mysterious', 'joyful', 'melancholic'],
        mood: ['serene', 'energetic', 'nostalgic', 'dramatic']
    };
    return (
        <div className="imgen-image-generator margin">
            <MessageDisplay message={message.text} type={message.type} />

            {/* Mobile View */}
            <div className="imgen-mobile-view">
                <div className="imgen-mobile-tabs">
                    <button
                        onClick={() => setMobileView('prompt')}
                        className={`imgen-tab-button ${mobileView === 'prompt' ? 'active' : ''}`}
                    >
                        Prompt
                    </button>
                    <button
                        onClick={() => setMobileView('settings')}
                        className={`imgen-tab-button ${mobileView === 'settings' ? 'active' : ''}`}
                    >
                        Settings
                    </button>
                </div>
                <div className="imgen-mobile-content">
                    <form onSubmit={handleSubmit}>
                        {mobileView === 'prompt' ? <div className="imgen-mobile-prompt-view">
                            <textarea
                                className="imgen-prompt-input"
                                placeholder="Enter Image Prompt:"
                                name="prompt"
                                value={formData.prompt}
                                onChange={handleChange}
                                required
                            />
                            <div className="imgen-word-count">
                                {wordCount}/300 words
                            </div>
                            <div className="imgen-button-group">
                                <button
                                    type="button"
                                    onClick={handleImprovePrompt}
                                    className="imgen-improve-button"
                                >
                                    Improve
                                </button>
                                <button
                                    type="button"
                                    onClick={handleRandomPrompt}
                                    className="imgen-random-button"
                                >
                                    Random
                                </button>
                            </div>
                            <button
                                type="submit"
                                disabled={isLoading || wordCount > 300}
                                className="imgen-generate-button"
                            >
                                {isLoading ? 'Generating...' : 'Generate Image'}
                            </button>
                        </div> : <div className="imgen-mobile-settings-view">
                            {['artStyle', 'perspective', 'theme', 'lighting', 'colorPalette', 'composition', 'emotionalTone', 'mood'].map((field) => (
                                <select
                                    key={field}
                                    name={field}
                                    value={formData[field]}
                                    onChange={handleChange}
                                    className="imgen-settings-select"
                                >
                                    <option value="">{`Select ${field.charAt(0).toUpperCase() + field.slice(1)}:`}</option>
                                    {fieldOptions[field].map(option => (
                                        <option key={option} value={option}>
                                            {option.charAt(0).toUpperCase() + option.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            ))}
                            <div className="imgen-dimension-inputs">
                                <input
                                    type="number"
                                    name="width"
                                    value={formData.width}
                                    onChange={handleChange}
                                    placeholder="Width 50 - 1280"
                                    min="50"
                                    max="1280"
                                    className="imgen-dimension-input"
                                />
                                <input
                                    type="number"
                                    name="height"
                                    value={formData.height}
                                    onChange={handleChange}
                                    placeholder="Height 50 - 1280"
                                    min="50"
                                    max="1280"
                                    className="imgen-dimension-input"
                                />
                            </div>
                        </div>}
                    </form>
                </div>
            </div>

            {/* Desktop View */}
            <div className="imgen-desktop-view">
                {/* Left Sidebar */}
                <div className="imgen-sidebar left-sidebar">
                    <form onSubmit={handleSubmit}>
                        <div className="imgen-mobile-prompt-view">
                            <textarea
                                className="imgen-prompt-input"
                                placeholder="Enter Image Prompt:"
                                name="prompt"
                                value={formData.prompt}
                                onChange={handleChange}
                                required
                            />
                            <div className="imgen-word-count">
                                {wordCount}/300 words
                            </div>
                            <div className="imgen-button-group">
                                <button
                                    type="button"
                                    onClick={handleImprovePrompt}
                                    className="imgen-improve-button"
                                >
                                    Improve
                                </button>
                                <button
                                    type="button"
                                    onClick={handleRandomPrompt}
                                    className="imgen-random-button"
                                >
                                    Random
                                </button>
                            </div>
                            <button
                                type="submit"
                                disabled={isLoading || wordCount > 300}
                                className="imgen-generate-button"
                            >
                                {isLoading ? 'Generating...' : 'Generate Image'}
                            </button>
                        </div>
                    </form>
                    <div className="imgen-action-buttons">
                        <button
                            onClick={handleDownload}
                            disabled={countdown !== null && countdown > 0}
                            className="imgen-download-button"
                        >
                            {countdown !== null && countdown > 0
                                ? `Downloading in ${countdown}...`
                                : 'Download Image'}
                        </button>
                        <button
                            onClick={handleSetAsProfile}
                            className="imgen-profile-button"
                        >
                            Set as Profile Picture
                        </button>
                        <button
                            onClick={handleSaveToFolder}
                            className="imgen-save-button"
                        >
                            Save to Album
                        </button>
                    </div>
                </div>

                {/* Right Sidebar */}
                <div className="imgen-sidebar right-sidebar">
                    <div className="imgen-mobile-settings-view">
                        {['artStyle', 'perspective', 'theme', 'lighting', 'colorPalette', 'composition', 'emotionalTone', 'mood'].map((field) => (
                            <select
                                key={field}
                                name={field}
                                value={formData[field]}
                                onChange={handleChange}
                                className="imgen-settings-select"
                            >
                                <option value="">{`Select ${field.charAt(0).toUpperCase() + field.slice(1)}:`}</option>
                                {fieldOptions[field].map(option => (
                                    <option key={option} value={option}>
                                        {option.charAt(0).toUpperCase() + option.slice(1)}
                                    </option>
                                ))}
                            </select>
                        ))}
                        <div className="imgen-dimension-inputs">
                            <input
                                type="number"
                                name="width"
                                value={formData.width}
                                onChange={handleChange}
                                placeholder="Width 50 - 1280"
                                min="50"
                                max="1280"
                                className="imgen-dimension-input"
                            />
                            <input
                                type="number"
                                name="height"
                                value={formData.height}
                                onChange={handleChange}
                                placeholder="Height 50 - 1280"
                                min="50"
                                max="1280"
                                className="imgen-dimension-input"
                            />
                        </div>
                    </div>
                </div>

                {/* Center Content */}
                <div className="imgen-main-content">
                    {!isLoading && generatedImageUrl && (
                        <div className="imgen-generated-image-container">
                            <h2 className="imgen-image-title">Generated Image</h2>
                            {isImageLoading && (
                                <div className="imgen-loading-spinner">
                                    <div className="imgen-spinner"></div>
                                </div>
                            )}
                            <div className="imgen-image-wrapper" style={{ width: imageDimensions.width, height: 'fit-content', maxWidth: '100%', margin: '0 auto' }}>
                                <canvas
                                    ref={desktopCanvasRef}
                                    className="imgen-generated-image"
                                />
                                <div className="imgen-image-overlay"></div>
                            </div>
                            {canvasError && <p className="imgen-error-message">{canvasError}</p>}
                        </div>
                    )}
                </div>
            </div>

            {/* Mobile Generated Image and Buttons */}
            <div className="imgen-mobile-generated-image">
                {!isLoading && generatedImageUrl && (
                    <div className="imgen-generated-image-container">
                        <h2 className="imgen-image-title">Generated Image</h2>
                        {isImageLoading && (
                            <div className="imgen-loading-spinner">
                                <div className="imgen-spinner"></div>
                            </div>
                        )}
                        <div className="imgen-image-wrapper" style={{ width: imageDimensions.width, height: 'fit-content', maxWidth: '100%', margin: '0 auto' }}>
                            <canvas
                                ref={mobileCanvasRef}
                                className="imgen-generated-image"
                            />
                            <div className="imgen-image-overlay"></div>
                        </div>
                        {canvasError && <p className="imgen-error-message">{canvasError}</p>}
                        <div className="imgen-action-buttons">
                            <button
                                onClick={handleDownload}
                                disabled={countdown !== null && countdown > 0}
                                className="imgen-download-button"
                            >
                                {countdown !== null && countdown > 0
                                    ? `Downloading in ${countdown}...`
                                    : 'Download Image'}
                            </button>
                            <button
                                onClick={handleSetAsProfile}
                                className="imgen-profile-button"
                            >
                                Set as Profile Picture
                            </button>
                            <button
                                onClick={handleSaveToFolder}
                                className="imgen-save-button"
                            >
                                Save to Album
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageGenerator;