import React, { useEffect, useRef, useState } from 'react';
import '../css/About.css';
import Footer from './Footer';
import { Link } from 'react-router-dom';

const About = () => {
  const firstColumnRef = useRef(null);
  const secondColumnRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  const aiTools = [
    "Find Recipes",
    "Generate Blogs",
    "Prepare Interview",
    "Plan Your Diet",
    "Complete Assignments",
    "Plan Gym Workouts",
    "Generate Images",
    "Find Startup Names",
    "Domain Name Ideas",
    "Find Nick Names",
    "Baby Name Suggestions",
    "Gift Suggestions",
    "Analyze Your Dreams",
    "Look For Hobbies",
    "Pet Name Ideas",
    "Startup Ideas",
    "Find Project Ideas",
    "Make Image Prompts",
    "Write Best Speeches",
    "Write Applications"
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const firstColumn = firstColumnRef.current;
    const secondColumn = secondColumnRef.current;

    if (firstColumn && secondColumn) {
      const animateScroll = () => {
        if (isMobile) {
          if (firstColumn.scrollLeft >= firstColumn.scrollWidth / 2) {
            firstColumn.scrollLeft = 0;
          } else {
            firstColumn.scrollLeft += 1;
          }

          if (secondColumn.scrollLeft <= 0) {
            secondColumn.scrollLeft = secondColumn.scrollWidth / 2;
          } else {
            secondColumn.scrollLeft -= 0.4;
          }
        } else {
          if (firstColumn.scrollTop >= firstColumn.scrollHeight / 2) {
            firstColumn.scrollTop = 0;
          } else {
            firstColumn.scrollTop += 0.8;
          }

          if (secondColumn.scrollTop <= 0) {
            secondColumn.scrollTop = secondColumn.scrollHeight / 2;
          } else {
            secondColumn.scrollTop -= 0.4;
          }
        }

        requestAnimationFrame(animateScroll);
      };

      requestAnimationFrame(animateScroll);
    }
  }, [isMobile]);


  return (
    <>
      <div className='home about-home'>
        <div className="left center">
          <h1 className='big-h'>Free ai tools by <span>devs</span><span>aura</span><span>.</span></h1>
          <p className=''>Unlock the power of artificial intelligence with
            our suite of free tools designed to enhance your
            productivity and creativity. Experience seamless
            tools—all at no cost. Explore our offerings and
            see how AI can transform your daily tasks and
            projects.</p>
        </div>
        <div className={`right ${isMobile ? 'mobile' : ''}`}>
          <div className="scroll-column first" ref={firstColumnRef}>
            {[...aiTools, ...aiTools].map((tool, index) => (
              <div key={`first-${index}`} className="item center">
                <p>{tool}</p>
              </div>
            ))}
          </div>
          <div className="scroll-column second" ref={secondColumnRef}>
            {[...aiTools, ...aiTools].map((tool, index) => (
              <div key={`second-${index}`} className="item center">
                <p>{tool}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="home-buttons">
        <Link to={"/create"}>Create Now</Link>
        <Link to={"/home"}>Explore</Link>
      </div>
      <Footer />
    </>
  );
};

export default About;