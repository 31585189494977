import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { authenticatedApiCall } from '../../utils/authenticatedApiCall';
import { Download, Clipboard, Trash2, Lock, Unlock, LogOut, Mail, UserX, Loader, Check, PencilIcon, Heart, UserPlus, UserMinus, Users } from 'lucide-react';
import '../../css/Profile.css'
import SocialLinks from './SocialLinks';

const Profile = () => {
    const [profileData, setProfileData] = useState({
        imageUrl: null,
        fullName: '',
        email: '',
        savedImageUrls: [],
        isPublic: true,
        followerCount: 0,
        followingCount: 0,
        isFollowing: false
    });

    const [error, setError] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isOwnProfile, setIsOwnProfile] = useState(false);
    const [isEditingBio, setIsEditingBio] = useState(false);
    const [isEditingSocialLinks, setIsEditingSocialLinks] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [followLoading, setFollowLoading] = useState(false);
    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [showFollowers, setShowFollowers] = useState(false);
    const [showFollowing, setShowFollowing] = useState(false);
    const profileImageCanvasRef = useRef(null);
    const savedImagesRefs = useRef([]);
    const followerImagesRefs = useRef([]);
    const followingImagesRefs = useRef([]);
    const navigate = useNavigate();
    const { username } = useParams();
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [buttonStates, setButtonStates] = useState({});
    const [likeInProgress, setLikeInProgress] = useState({});


    const fetchFollowers = async () => {
        try {
            const response = await authenticatedApiCall(`/followers/${username || profileData.username}`, 'GET');
            console.log("data: ", username, profileData.username);
            setFollowers(response);
        } catch (error) {
            console.error('Error fetching followers:', error);
            setError('Error fetching followers');
        }
    };

    const fetchFollowing = async () => {
        try {
            const response = await authenticatedApiCall(`/following/${username || profileData.username}`, 'GET');
            setFollowing(response);
        } catch (error) {
            console.error('Error fetching following:', error);
            setError('Error fetching following');
        }
    };

    const toggleFollowersList = () => {
        setShowFollowers(!showFollowers);
        setShowFollowing(false);
        if (!showFollowers && followers.length === 0) {
            fetchFollowers();
        }
    };

    const toggleFollowingList = () => {
        setShowFollowing(!showFollowing);
        setShowFollowers(false);
        if (!showFollowing && following.length === 0) {
            fetchFollowing();
        }
    };

    useEffect(() => {
        if (showFollowers) {
            followers.forEach((follower, index) => {
                if (followerImagesRefs.current[index]) {
                    drawImageOnCanvas(follower.profile_image_url || '/default-avatar.png', followerImagesRefs.current[index]);
                }
            });
        }
    }, [showFollowers, followers]);

    useEffect(() => {
        if (showFollowing) {
            following.forEach((followed, index) => {
                if (followingImagesRefs.current[index]) {
                    drawImageOnCanvas(followed.profile_image_url || '/default-avatar.png', followingImagesRefs.current[index]);
                }
            });
        }
    }, [showFollowing, following]);

    const handleFollow = async () => {
        setFollowLoading(true);
        try {
            const response = await authenticatedApiCall(`/follow/${username}`, 'POST');
            if (response.message) {
                setIsFollowing(true);
                setProfileData(prev => ({
                    ...prev,
                    followerCount: prev.followerCount + 1
                }));
                setError(response.message);
                setTimeout(() => setError(null), 3000);
            }
        } catch (error) {
            setError('Error following user: ' + error.message);
        } finally {
            setFollowLoading(false);
        }
    };

    const handleUnfollow = async () => {
        setFollowLoading(true);
        try {
            const response = await authenticatedApiCall(`/unfollow/${username}`, 'POST');
            if (response.message) {
                setIsFollowing(false);
                setProfileData(prev => ({
                    ...prev,
                    followerCount: prev.followerCount - 1
                }));
                setError(response.message);
                setTimeout(() => setError(null), 3000);
            }
        } catch (error) {
            setError('Error unfollowing user: ' + error.message);
        } finally {
            setFollowLoading(false);
        }
    };

    const handleImageClick = (index) => {
        if (window.innerWidth <= 768) {
            setSelectedImageIndex(prevIndex => prevIndex === index ? null : index);
        } else {
            setSelectedImageIndex(index === selectedImageIndex ? null : index);
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest('.profile-image-card')) {
                setSelectedImageIndex(null);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        fetchProfileDetails();
        // eslint-disable-next-line
    }, [username]);

    const fetchProfileDetails = async () => {
        try {
            let response;
            if (username) {
                response = await authenticatedApiCall(`/get-user-profile?username=${username}`, 'GET');
                setIsOwnProfile(false);
                setIsFollowing(response.isFollowing);
            } else {
                response = await authenticatedApiCall('/get-profile-details', 'GET');
                setIsOwnProfile(true);
            }
            setProfileData(response);
        } catch (error) {
            console.error('Error fetching profile details:', error);
            setError('Error fetching profile details');
        }
    };

    const handleLike = async (imageId, index) => {
        if (!imageId || likeInProgress[imageId]) return;

        setLikeInProgress(prev => ({ ...prev, [imageId]: true }));

        const currentImage = profileData.savedImageUrls[index];
        const currentLikeCount = currentImage.likeCount;
        const isLiked = currentImage.isLiked;

        try {
            // Optimistically update UI
            setProfileData(prevData => ({
                ...prevData,
                savedImageUrls: prevData.savedImageUrls.map((img, idx) =>
                    idx === index ? { ...img, likeCount: isLiked ? currentLikeCount - 1 : currentLikeCount + 1, isLiked: !isLiked } : img
                )
            }));

            const response = await authenticatedApiCall(`/update-like-count/${imageId}`, 'POST', {
                increment: isLiked ? -1 : 1
            });

            if (!response.success) {
                throw new Error(response.error || 'Failed to update like count');
            }
        } catch (err) {
            console.error('Error updating like count:', err);
            setError(`Error updating like: ${err.message}`);

            // Revert the optimistic update
            setProfileData(prevData => ({
                ...prevData,
                savedImageUrls: prevData.savedImageUrls.map((img, idx) =>
                    idx === index ? { ...img, likeCount: currentLikeCount, isLiked: isLiked } : img
                )
            }));
        } finally {
            setLikeInProgress(prev => ({ ...prev, [imageId]: false }));
        }
    };

    const handleUpdateBio = async (newBio) => {
        try {
            const response = await authenticatedApiCall('/update-bio', 'POST', { bio: newBio });
            if (response.success) {
                setProfileData(prevData => ({ ...prevData, bio: newBio }));
                setIsEditingBio(false);
                setError('Bio updated successfully!');
                setTimeout(() => setError(null), 4000);
            } else {
                setError('Failed to update bio');
            }
        } catch (error) {
            setError('Error updating bio: ' + error.message);
        }
    };

    const handleUpdateSocialLinks = async (newLinks) => {
        try {
            const response = await authenticatedApiCall('/update-social-links', 'POST', { socialLinks: newLinks });
            if (response.success) {
                setProfileData(prevData => ({ ...prevData, socialLinks: newLinks }));
                setIsEditingSocialLinks(false);
                setError('Social links updated successfully!');
                setTimeout(() => setError(null), 4000);
            } else {
                setError('Failed to update social links');
                setTimeout(() => setError(null), 4000);
            }
        } catch (error) {
            setError('Error updating social links: ' + error.message);
            setTimeout(() => setError(null), 5000);
        }
    };

    const toggleProfileVisibility = async () => {
        try {
            const response = await authenticatedApiCall('/toggle-profile-visibility', 'POST');
            if (response.success) {
                setProfileData(prevData => ({
                    ...prevData,
                    isPublic: response.isPublic
                }));
                setError('Profile preferece updated!');
                setTimeout(() => setError(null), 4000);
            } else {
                setError('Failed to toggle profile visibility');
            }
        } catch (error) {
            setError('Error toggling profile visibility: ' + error.message);
        }
    };

    const handleDeleteImage = async (imageId, index) => {
        const confirmed = window.confirm("Are you sure you want to delete this image? This action cannot be undone.");
        setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], delete: 'loading' } }));
        if (!confirmed) {
            setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], delete: 'default' } }));
            return;
        }
        try {
            const response = await authenticatedApiCall('/delete-saved-image', 'DELETE', { imageId });
            if (response.success) {
                setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], delete: 'default' } }));
                setProfileData(prevData => ({
                    ...prevData,
                    savedImageUrls: prevData.savedImageUrls.filter(img => img.id !== imageId)
                }));
            } else {
                setError('Failed to delete image');
                setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], delete: 'default' } }));
            }
        } catch (error) {
            setError('Error deleting image: ' + error.message);
            setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], delete: 'default' } }));
        }
    };


    const handleDeleteAccount = async () => {
        if (window.confirm("Are you sure you want to delete your account? All of your generated art will be deleted permanently. This action cannot be undone.")) {
            setIsDeleting(true);
            try {
                const response = await authenticatedApiCall('/delete-account', 'DELETE');
                if (response.success) {
                    localStorage.removeItem('token');
                    navigate('/login');
                } else {
                    setError('Failed to delete account');
                }
            } catch (error) {
                setError('Error deleting account: ' + error.message);
            } finally {
                setIsDeleting(false);
            }
        }
    };

    useEffect(() => {
        if (profileData.imageUrl) {
            drawImageOnCanvas(profileData.imageUrl, profileImageCanvasRef.current);
        }
    }, [profileData.imageUrl]);

    useEffect(() => {
        profileData.savedImageUrls.forEach((imageData, index) => {
            if (savedImagesRefs.current[index]) {
                drawImageOnCanvas(imageData.imageUrl, savedImagesRefs.current[index]);
            }
        });
    }, [profileData.savedImageUrls]);

    const drawImageOnCanvas = (imageUrl, canvas) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = () => {
            if (canvas) {
                // Extract width and height from URL
                const urlParams = new URLSearchParams(new URL(imageUrl).search);
                const width = parseInt(urlParams.get('width')) || img.naturalWidth;
                const height = parseInt(urlParams.get('height')) || img.naturalHeight;

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
            }
        };
        img.src = imageUrl;
    };

    const handleDownload = async (imageUrl, index) => {
        // Set button state to loading
        setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], download: 'loading' } }));
        try {
            const canvas = savedImagesRefs.current[index];
            const ctx = canvas.getContext('2d');

            // Add watermark to the canvas
            ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
            ctx.font = '16px Arial';
            ctx.textAlign = 'right';
            ctx.textBaseline = 'bottom';
            ctx.fillText('ai.devsaura.com', canvas.width - 10, canvas.height - 10);

            // Convert canvas to a blob for downloading
            const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
            const url = window.URL.createObjectURL(blob);

            // Function to extract and sanitize filename from the URL
            const extractFilename = (url) => {
                const prompt = extractPromptFromUrl(url); // Assumed to extract the prompt from the URL or other data
                const words = prompt.trim().split(/\s+/);
                const filenameBase = words.slice(0, 8).join('_') || 'image'; // Use first 8 words as base filename
                const safeBase = filenameBase.length > 45 ? filenameBase.slice(0, 45) : filenameBase; // Limit to 45 characters
                const sanitizedBase = safeBase.replace(/[<>:"/\\|?*]/g, '_'); // Remove invalid characters for filenames
                return `${sanitizedBase}.jpg`;
            };

            // Generate the filename
            const filename = extractFilename(imageUrl);

            // Create a temporary link element to trigger the download
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Revoke the temporary URL to release memory
            window.URL.revokeObjectURL(url);

            // Set button state to success
            setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], download: 'success' } }));
            setTimeout(() => {
                setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], download: 'default' } }));
            }, 2000);
        } catch (error) {
            console.error('Download error:', error);
            setError("An error occurred while downloading the image.");
            setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], download: 'default' } }));
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.replace('/login');
    };
    const extractPromptFromUrl = (url) => {
        try {
            const urlObj = new URL(url);
            const promptPath = urlObj.pathname.split('/prompt/')[1];
            return decodeURIComponent(promptPath);
        } catch (error) {
            console.error('Error extracting prompt from URL:', error);
            return '';
        }
    };
    const copyPromptToClipboard = (imageUrl, index) => {
        // Set button state to loading
        setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], copy: 'loading' } }));
        const prompt = extractPromptFromUrl(imageUrl);
        navigator.clipboard.writeText(prompt).then(() => {
            // Set button state to success
            setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], copy: 'success' } }));
            setTimeout(() => {
                setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], copy: 'default' } }));
            }, 2000);
            setError('Prompt copied to clipboard!');
            setTimeout(() => setError(null), 3000);
        }).catch(err => {
            console.error('Failed to copy prompt: ', err);
            setButtonStates(prev => ({ ...prev, [index]: { ...prev[index], copy: 'default' } }));
        });
    };
    return (
        <div className="profile-profile-container margin">
            <div className="profile-profile-card">
                <div className="profile-profile-header">
                    <canvas ref={profileImageCanvasRef} className="profile-profile-image" />
                    <div className="profile-profile-info">
                        <h1 className="profile-profile-name">{profileData.fullName}</h1>
                        <p className="profile-profile-email">{profileData.email}</p>
                        {/* Bio section */}
                        {isEditingBio ? (
                            <div className="profile-bio-edit">
                                <textarea
                                    defaultValue={profileData.bio}
                                    maxLength={100}
                                    onChange={(e) => setProfileData(prev => ({ ...prev, bio: e.target.value }))}
                                />
                                <div className="profile-bio-edit-buttons">
                                    <button onClick={() => handleUpdateBio(profileData.bio)}>Save</button>
                                    <button onClick={() => setIsEditingBio(false)}>Cancel</button>
                                </div>
                            </div>
                        ) : (
                            <div className="profile-bio">
                                <p>{profileData.bio || "No bio yet."}</p>
                                {isOwnProfile && (
                                    <button onClick={() => setIsEditingBio(true)} className="profile-icon-button">
                                        <PencilIcon size={16} />
                                    </button>
                                )}
                            </div>
                        )}
                        {/* Social Links section */}
                        <SocialLinks
                            profileData={profileData}
                            isOwnProfile={isOwnProfile}
                            isEditingSocialLinks={isEditingSocialLinks}
                            setIsEditingSocialLinks={setIsEditingSocialLinks}
                            handleUpdateSocialLinks={handleUpdateSocialLinks}
                            setProfileData={setProfileData}
                        />
                        <div className="profile-stats">
                            <button className="profile-stat-button" onClick={toggleFollowersList}>
                                <Users size={20} />
                                <span>{profileData.followerCount} Followers</span>
                            </button>
                            <button className="profile-stat-button" onClick={toggleFollowingList}>
                                <Users size={20} />
                                <span>{profileData.followingCount} Following</span>
                            </button>
                        </div>
                        {showFollowers && (
                            <div className="followers-list">
                                <h3>Followers</h3>
                                {followers.map((follower, index) => (
                                    <Link
                                        key={follower.username}
                                        to={`/profile/${follower.username}`}
                                        className="follower-item"
                                        onClick={() => {
                                            setShowFollowers(false);  // Close followers list when clicked
                                            setShowFollowing(false);  // Close following list if open
                                        }}
                                    >
                                        <canvas
                                            ref={el => followerImagesRefs.current[index] = el}
                                            className="follower-image"
                                        />
                                        <span>{follower.username || follower.name}</span>
                                    </Link>
                                ))}
                            </div>
                        )}
                        {showFollowing && (
                            <div className="following-list">
                                <h3>Following</h3>
                                {following.map((followed, index) => (
                                    <Link
                                        key={followed.username}
                                        to={`/profile/${followed.username}`}
                                        className="following-item"
                                        onClick={() => {
                                            setShowFollowing(false);  // Close following list when clicked
                                            setShowFollowers(false);  // Close followers list if open
                                        }}
                                    >
                                        <canvas
                                            ref={el => followingImagesRefs.current[index] = el}
                                            className="following-image"
                                        />
                                        <span>{followed.username || followed.name}</span>
                                    </Link>
                                ))}
                            </div>
                        )}
                        {!isOwnProfile && (
                            <button
                                className={`profile-follow-button ${isFollowing ? 'following' : ''}`}
                                onClick={isFollowing ? handleUnfollow : handleFollow}
                                disabled={followLoading}
                            >
                                {followLoading ? (
                                    <Loader size={20} className="animate-spin" />
                                ) : isFollowing ? (
                                    <>
                                        <UserMinus size={20} />
                                        <span>Unfollow</span>
                                    </>
                                ) : (
                                    <>
                                        <UserPlus size={20} />
                                        <span>Follow</span>
                                    </>
                                )}
                            </button>
                        )}
                        <div className="profile-seperator-line"></div>
                        {isOwnProfile && (
                            <div className='profile-actions-container'>
                                <div className="profile-visibility-toggle">
                                    <button
                                        onClick={toggleProfileVisibility}
                                        className="profile-icon-button"
                                        title={profileData.isPublic ? "Lock profile: Profile will not appear in explore and saved album will be hidden" : "Unlock profile: Profile may appear in explore and people can see your saved album"}
                                    >
                                        {profileData.isPublic ? <Unlock size={20} /> : <Lock size={20} />}
                                    </button>
                                    <Link to="/" className="profile-icon-button" title="Contact Us">
                                        <Mail size={20} />
                                    </Link>
                                    <button onClick={handleLogout} className="profile-icon-button" title="Logout Account">
                                        <LogOut size={20} />
                                    </button>
                                </div>
                                <div className="delete-account-button">
                                    <button
                                        onClick={handleDeleteAccount}
                                        disabled={isDeleting}
                                        className="profile-icon-button delete"
                                        title="Delete Account Permanently"
                                    >
                                        <UserX size={20} />
                                    </button>
                                </div>
                            </div>
                        )}
                        {!profileData.isPublic && !isOwnProfile && (
                            <p className="profile-private-profile-message">This profile is Locked!</p>
                        )}
                    </div>
                </div>
            </div>
            {(isOwnProfile || profileData.isPublic) && (
                <div className="profile-album-section">
                    <h2 className="profile-album-title">Saved Album</h2>
                    {profileData.savedImageUrls && profileData.savedImageUrls.length > 0 ? (
                        <div className="profile-image-grid">
                            {profileData.savedImageUrls.map((image, index) => (
                                <div
                                    key={image.id}
                                    className={`profile-image-card ${selectedImageIndex === index ? 'selected' : ''}`}
                                    onClick={() => handleImageClick(index)}
                                >
                                    <div className="profile-image-wrapper">
                                        <canvas
                                            ref={el => savedImagesRefs.current[index] = el}
                                            className="profile-saved-image"
                                        />
                                        <div className={`profile-image-overlay ${selectedImageIndex === index ? 'visible' : ''}`}>
                                            {!isOwnProfile && (
                                                <button
                                                    className={`profile-image-action-button profile-image-likes ${image.isLiked ? 'liked' : ''}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleLike(image.id, index);
                                                    }}
                                                    disabled={likeInProgress[image.id]}
                                                >
                                                    <Heart
                                                        size={20}
                                                        fill={image.isLiked ? 'red' : 'none'}
                                                        color={image.isLiked ? 'red' : '#fff'}
                                                    />
                                                    {image.likeCount}
                                                </button>
                                            )}
                                            <button
                                                onClick={(e) => { e.stopPropagation(); handleDownload(image.imageUrl, index); }}
                                                className="profile-image-action-button"
                                                disabled={buttonStates[index]?.download === 'loading'}
                                            >
                                                {buttonStates[index]?.download === 'loading' ? (
                                                    <Loader size={20} className="profile-action-icon animate-spin" />
                                                ) : buttonStates[index]?.download === 'success' ? (
                                                    <Check size={20} className="profile-action-icon" />
                                                ) : (
                                                    <Download size={20} className="profile-action-icon" />
                                                )}
                                            </button>
                                            <button
                                                onClick={(e) => { e.stopPropagation(); copyPromptToClipboard(image.imageUrl, index); }}
                                                className="profile-image-action-button"
                                                disabled={buttonStates[index]?.copy === 'loading'}
                                            >
                                                {buttonStates[index]?.copy === 'loading' ? (
                                                    <Loader size={20} className="profile-action-icon animate-spin" />
                                                ) : buttonStates[index]?.copy === 'success' ? (
                                                    <Check size={20} className="profile-action-icon" />
                                                ) : (
                                                    <Clipboard size={20} className="profile-action-icon" />
                                                )}
                                            </button>
                                            {isOwnProfile && (
                                                <button
                                                    onClick={(e) => { e.stopPropagation(); handleDeleteImage(image.id, index); }}
                                                    className="profile-image-action-button delete"
                                                    disabled={buttonStates[index]?.delete === 'loading'}
                                                >
                                                    {buttonStates[index]?.delete === 'loading' ? (
                                                        <Loader size={20} className="profile-action-icon delete animate-spin" />
                                                    ) : (
                                                        <Trash2 size={20} className="profile-action-icon delete" />
                                                    )}
                                                </button>
                                            )}
                                        </div>
                                        {/* <div className="profile-image-tags">
                                            {image.tags.map((tag, tagIndex) => (
                                                <span key={tagIndex} className="profile-image-tag">{tag}</span>
                                            ))}
                                        </div> */}
                                        {/* <div className="profile-image-info">
                                            <span className="profile-image-likes">
                                                <Heart size={16} /> {image.likeCount}
                                            </span>
                                            <span className="profile-image-timestamp">
                                                {new Date(image.timestamp).toLocaleDateString()}
                                            </span>
                                            {image.isAdult && <span className="profile-image-adult-content">18+</span>}
                                        </div> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="profile-no-images-message">No saved images yet.</p>
                    )}
                </div>
            )}
            {error && <div className="profile-error-message">{error}</div>}
        </div>
    );
};

export default Profile;