import React from 'react';
import ToolContent from '../ToolContent';

const BlogGenerator = () => {
  const formFields = [
    { name: 'topic', type: 'text', placeholder: 'Blog Topic:', required: true },
    {
      name: 'tone',
      type: 'select',
      placeholder: 'Select Tone:',
      options: [
        { value: 'Informative', label: 'Informative' },
        { value: 'Casual', label: 'Casual' },
        { value: 'Professional', label: 'Professional' },
        { value: 'Conversational', label: 'Conversational' },
        { value: 'Humorous', label: 'Humorous' },
      ],
      required: true
    },
    { name: 'audience', type: 'text', placeholder: 'Target Audience:', required: true },
    { name: 'keywords', type: 'text', placeholder: 'Comma-separated keywords' },
    { name: 'wordCount', type: 'number', placeholder: 'Word Count: 100 - 2000', min: 100, max: 2000 },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_BLOG}
      title="Blog Generator"
      imageName="blog_image"
    />
  );
};

export default BlogGenerator;