import React from 'react';
import ToolContent from '../ToolContent';

const DreamAnalyzer = () => {
  const formFields = [
    { name: 'dreamDescription', type: 'textarea', placeholder: 'Describe your dream:', required: true },
    { name: 'emotions', type: 'text', placeholder: 'Emotions felt during the dream:' },
    { name: 'keySymbols', type: 'text', placeholder: 'Key symbols or objects in the dream:' },
    { name: 'dreamSetting', type: 'text', placeholder: 'Dream setting or location:' },
    { name: 'recurringThemes', type: 'textarea', placeholder: 'Recurring themes or patterns:' },
    { name: 'recentLifeEvents', type: 'textarea', placeholder: 'Recent life events or changes:' },
    { name: 'personalContext', type: 'textarea', placeholder: 'Additional personal context or background:' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_DREAM_ANALYZER}
      title="Dream Analysis"
      imageName = "dream_analysis"
    />
  );
};

export default DreamAnalyzer;