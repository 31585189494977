import React from 'react';
import ToolContent from '../ToolContent';

const BabyNameGenerator = () => {
  const formFields = [
    {
      name: 'gender',
      type: 'select',
      placeholder: 'Select Gender:',
      options: [
        { value: 'boy', label: 'Boy' },
        { value: 'girl', label: 'Girl' },
        { value: 'unisex', label: 'Unisex' },
      ],
      required: true
    },
    { name: 'nameOrigin', type: 'text', placeholder: 'Name Origin (e.g., English, Spanish):' },
    {
      name: 'nameStyle',
      type: 'select',
      placeholder: 'Select Name Style:',
      options: [
        { value: 'traditional', label: 'Traditional' },
        { value: 'modern', label: 'Modern' },
        { value: 'unique', label: 'Unique' },
        { value: 'classic', label: 'Classic' },
      ],
      required: true
    },
    {
      name: 'nameLength',
      type: 'select',
      placeholder: 'Select Name Length:',
      options: [
        { value: 'short', label: 'Short' },
        { value: 'medium', label: 'Medium' },
        { value: 'long', label: 'Long' },
      ],
      required: true
    },
    { name: 'startingLetter', type: 'text', placeholder: 'Starting Letter (if any):' },
    { name: 'middleNamePreference', type: 'text', placeholder: 'Middle Name Preference (if any):' },
    { name: 'familyNames', type: 'textarea', placeholder: 'Family Names or Surnames (to consider or avoid):' },
    { name: 'personalPreferences', type: 'textarea', placeholder: 'Personal Preferences (additional notes):' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_BABY_NAMES}
      title="Baby Name Generator"
      imageName="baby_name_image"
    />
  );
};

export default BabyNameGenerator;