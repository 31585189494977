import React from 'react';
import { PencilIcon } from 'lucide-react';
import { FaInstagram, FaFacebook, FaTwitter, FaGlobe } from 'react-icons/fa';
import '../../css/SocialLinks.css';

const getSocialIcon = (url) => {
    if (url.includes('instagram.com')) return <FaInstagram size={20} />;
    if (url.includes('facebook.com')) return <FaFacebook size={20} />;
    if (url.includes('twitter.com')) return <FaTwitter size={20} />;
    return <FaGlobe size={20} />;
};

const SocialLinks = ({ profileData, isOwnProfile, isEditingSocialLinks, setIsEditingSocialLinks, handleUpdateSocialLinks, setProfileData }) => {
    return (
        <div className="profile-social-links-container">
            {isEditingSocialLinks ? (
                <div className="profile-social-links-edit">
                    <div className="profile-social-links-edit-inputs">
                        <input
                            type="text"
                            defaultValue={profileData.socialLinks.instagram}
                            placeholder='Instagram'
                            onChange={(e) => setProfileData(prev => ({ ...prev, socialLinks: { ...prev.socialLinks, instagram: e.target.value } }))} />
                        <input
                            type="text"
                            defaultValue={profileData.socialLinks.facebook}
                            placeholder='Facebook'
                            onChange={(e) => setProfileData(prev => ({ ...prev, socialLinks: { ...prev.socialLinks, facebook: e.target.value } }))} />
                        <input
                            type="text"
                            defaultValue={profileData.socialLinks.twitter}
                            placeholder='X (twitter)'
                            onChange={(e) => setProfileData(prev => ({ ...prev, socialLinks: { ...prev.socialLinks, twitter: e.target.value } }))} />
                    </div>
                    <div className="profile-social-links-edit-buttons">
                        <button onClick={() => handleUpdateSocialLinks(profileData.socialLinks)}>Save</button>
                        <button onClick={() => setIsEditingSocialLinks(false)}>Cancel</button>
                    </div>
                </div>
            ) : (
                <div className="profile-social-links">
                    {Object.entries(profileData.socialLinks || {}).map(([platform, link]) => (
                        link && (
                            <a key={platform} href={link} target="_blank" rel="noopener noreferrer" className="social-link">
                                {getSocialIcon(link)}
                            </a>
                        )
                    ))}
                    {isOwnProfile && (
                        <button onClick={() => setIsEditingSocialLinks(true)} className="profile-icon-edit-button">
                            <PencilIcon size={16} />
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default SocialLinks;
