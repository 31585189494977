import React from 'react';
import ToolContent from '../ToolContent';

const AssignmentMaker = () => {
  const formFields = [
    { name: 'assignmentQuestions', type: 'textarea', placeholder: 'Enter Assignment Questions:', required: true },
    {
      name: 'answerLength',
      type: 'select',
      placeholder: 'Select Length of Answers:',
      options: [
        { value: 'Short', label: 'Short' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Long', label: 'Long' },
      ],
      required: true
    },
    {
      name: 'englishLevel',
      type: 'select',
      placeholder: 'Select English Level:',
      options: [
        { value: 'Easy', label: 'Easy' },
        { value: 'Intermediate', label: 'Intermediate' },
        { value: 'Complex', label: 'Complex' },
      ],
      required: true
    },
    { name: 'specificInstructions', type: 'textarea', placeholder: 'Any Specific Instructions:' },
    { name: 'humanizeText', type: 'checkbox', label: 'Humanize the Text' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_ASSIGNMENT}
      title="Assignment Maker"
      imageName="assignment_image"
    />
  );
};

export default AssignmentMaker;