import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Copy, Download } from 'lucide-react';
import { authenticatedApiCall } from '../utils/authenticatedApiCall';
import { isLoggedIn } from '../utils/cookies';
import '../css/ToolContent.css'

const ToolContent = ({
  title,
  apiEndpoint,
  formFields,
  imageName = 'ai_generated_image'
}) => {
  const [formData, setFormData] = useState({});
  const [generatedContent, setGeneratedContent] = useState('');
  const [generatedImageUrls, setGeneratedImageUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [downloadError, setDownloadError] = useState(null);
  const [mobileView, setMobileView] = useState('content');

  const desktopCanvasRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef()
  ]);

  const mobileCanvasRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef()
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setGeneratedContent('');
    setGeneratedImageUrls([]);
    setError(null);

    try {
      const response = await authenticatedApiCall(apiEndpoint, 'POST', formData);

      if (response.content && response.imageUrls) {
        setGeneratedContent(response.content);
        setGeneratedImageUrls(response.imageUrls);
      } else {
        throw new Error('No content or image URLs in the response');
      }
    } catch (error) {
      console.error('Error details:', error);
      setError(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedContent).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    }, (err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleDownload = async (index, isMobile) => {
    if (!isLoggedIn()) {
      startCountdown(index);
      return;
    }
    downloadImage(index, isMobile);
  };

  const startCountdown = (index) => {
    setCountdown({ index, time: 5 });
  };

  const downloadImage = async (index, isMobile) => {
    setDownloadError(null);

    const canvasRef = isMobile ? mobileCanvasRefs.current[index] : desktopCanvasRefs.current[index];

    if (canvasRef && canvasRef.current) {
      try {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // Add watermark
        ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
        ctx.font = '16px Arial';
        ctx.textAlign = 'right';
        ctx.textBaseline = 'bottom';
        ctx.fillText('ai.devsaura.com', canvas.width - 10, canvas.height - 10);

        const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
        const url = window.URL.createObjectURL(blob);

        const extractFilename = (prompt) => {
          const words = (formData.prompt || '').trim().split(/\s+/);
          const filenameBase = words.slice(0, 4).join('_') || imageName;
          const safeBase = filenameBase.length > 45 ? filenameBase.slice(0, 45) : filenameBase;
          const sanitizedBase = safeBase.replace(/[<>:"/\\|?*]/g, '_');
          return `${sanitizedBase}_${index + 1}.jpg`;
        };

        const filename = extractFilename(formData.prompt);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        if (!isLoggedIn()) {
          setDownloadError("Log in to download images instantly.");
          setTimeout(() => setDownloadError(null), 5000);
        }
      } catch (error) {
        console.error('Download error:', error);
        setDownloadError("An error occurred while downloading the image.");
      }
    }
  };

  useEffect(() => {
    let timer;
    if (countdown !== null && countdown.time > 0) {
      timer = setTimeout(() => {
        setCountdown(prev => ({ ...prev, time: prev.time - 1 }));
      }, 1000);
    } else if (countdown !== null && countdown.time === 0) {
      downloadImage(countdown.index, false);
      setCountdown(null);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [countdown]);

  useEffect(() => {
    generatedImageUrls.forEach((url, index) => {
      if (index < 4 && url) {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
          [desktopCanvasRefs, mobileCanvasRefs].forEach(refSet => {
            const canvas = refSet.current[index].current;
            if (canvas) {
              canvas.width = img.width;
              canvas.height = img.height;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
            }
          });
        };
        img.onerror = () => {
          console.error(`Failed to load image: ${url}`);
        };
        img.src = url;
      }
    });
  }, [generatedImageUrls]);

  return (
    <div className="tc-tool-content margin">
      {/* Mobile View */}
      <div className="tc-mobile-view">
        <div className="tc-mobile-tabs">
          <button
            onClick={() => setMobileView('content')}
            className={`tc-tab-button ${mobileView === 'content' ? 'tc-active' : ''}`}
          >
            Content
          </button>
          <button
            onClick={() => setMobileView('images')}
            className={`tc-tab-button ${mobileView === 'images' ? 'tc-active' : ''}`}
          >
            Images
          </button>
        </div>
        <div className="tc-mobile-content">
          <h1 className="tc-title">{title}</h1>
          <div className={`tc-mobile-content-view ${mobileView === 'content' ? '' : 'tc-hidden'}`}>
            <form onSubmit={handleSubmit} className="tc-form">
              {formFields.map((field) => (
                <div key={field.name}>
                  {field.type === 'select' ? (
                    <select
                      name={field.name}
                      value={formData[field.name] || ''}
                      onChange={handleInputChange}
                      required={field.required}
                      className="tc-select-input"
                    >
                      <option value="" disabled>{field.placeholder}</option>
                      {field.options.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name] || ''}
                      onChange={handleInputChange}
                      required={field.required}
                      className="tc-text-input"
                    />
                  )}
                </div>
              ))}
              <button
                type="submit"
                disabled={isLoading}
                className="tc-submit-button"
              >
                {isLoading ? 'Generating...' : 'Generate'}
              </button>
            </form>
            {isLoading && (
              <div className="tc-loading-spinner">
                <div className="tc-spinner"></div>
              </div>
            )}
            {error && <div className="tc-error-message">{error}</div>}
            {!isLoading && !error && generatedContent && (
              <div className="tc-generated-content">
                <div className="tc-markdown-content">
                  <ReactMarkdown>{generatedContent}</ReactMarkdown>
                </div>
                <div className="tc-copy-button-container">
                  <button
                    onClick={handleCopy}
                    className="tc-copy-button"
                  >
                    <Copy size={16} />
                    <span>{copySuccess ? 'Copied!' : 'Copy'}</span>
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className={`tc-mobile-images-view ${mobileView === 'images' ? '' : 'tc-hidden'}`}>
            {[0, 1, 2, 3].map((index) => (
              <div key={index} className="tc-image-container">
                <canvas
                  ref={mobileCanvasRefs.current[index]}
                  className="tc-image-canvas"
                />
                {generatedImageUrls[index] && (
                  <button
                    onClick={() => handleDownload(index, true)}
                    disabled={countdown !== null && countdown.index === index && countdown.time > 0}
                    className="tc-download-button"
                  >
                    {countdown !== null && countdown.index === index && countdown.time > 0
                      ? `${countdown.time}...`
                      : <Download size={20} />
                    }
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Desktop View */}
      <div className="tc-desktop-view">
        {/* Left Sidebar - Form Fields */}
        <div className="tc-sidebar left-sidebar">
          <h1 className="tc-title">{title}</h1>
          <form onSubmit={handleSubmit} className="tc-form">
            {formFields.map((field) => (
              <div key={field.name}>
                {field.type === 'select' ? (
                  <select
                    name={field.name}
                    value={formData[field.name] || ''}
                    onChange={handleInputChange}
                    required={field.required}
                    className="tc-select-input"
                  >
                    <option value="" disabled>{field.placeholder}</option>
                    {field.options.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={field.type}
                    name={field.name}
                    placeholder={field.placeholder}
                    value={formData[field.name] || ''}
                    onChange={handleInputChange}
                    required={field.required}
                    className="tc-text-input"
                  />
                )}
              </div>
            ))}
            <button
              type="submit"
              disabled={isLoading}
              className="tc-submit-button"
            >
              {isLoading ? 'Generating...' : 'Generate'}
            </button>
          </form>
        </div>

        {/* Center Content - Generated Content */}
        <div className="tc-main-content">
          {isLoading && (
            <div className="tc-loading-spinner">
              <div className="tc-spinner"></div>
            </div>
          )}
          {error && <div className="tc-error-message">{error}</div>}
          {!isLoading && !error && (
            <div className="tc-generated-content">
              <div className="tc-markdown-content">
                <ReactMarkdown>{generatedContent}</ReactMarkdown>
              </div>
              {generatedContent && (
                <div className="tc-copy-button-container">
                  <button
                    onClick={handleCopy}
                    className="tc-copy-button"
                  >
                    <Copy size={16} />
                    <span>{copySuccess ? 'Copied!' : 'Copy'}</span>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Right Sidebar - Generated Images */}
        <div className="tc-sidebar right-sidebar">
          <h2 className="tc-subtitle">Generated Images</h2>
          <div className="tc-image-grid">
            {[0, 1, 2, 3].map((index) => (
              <div key={index} className="tc-image-container">
                <canvas
                  ref={desktopCanvasRefs.current[index]}
                  className="tc-image-canvas"
                />
                {generatedImageUrls[index] && (
                  <button
                    onClick={() => handleDownload(index, false)}
                    disabled={countdown !== null && countdown.index === index && countdown.time > 0}
                    className="tc-download-button"
                  >
                    {countdown !== null && countdown.index === index && countdown.time > 0
                      ? `${countdown.time}...`
                      : <Download size={20} />
                    }
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {downloadError && (
        <div className="tc-download-error">{downloadError}</div>
      )}
    </div>
  );
};

export default ToolContent;