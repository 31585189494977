import React from 'react';
import ToolContent from '../ToolContent';

const WorkoutPlanner = () => {
  const formFields = [
    {
      name: 'experienceLevel',
      type: 'select',
      placeholder: 'Select Experience Level:',
      options: [
        { value: 'new', label: 'New to the Gym' },
        { value: 'some_experience', label: 'Some Experience' },
        { value: 'experienced', label: 'Experienced' },
      ],
      required: true
    },
    {
      name: 'fitnessGoals',
      type: 'select',
      placeholder: 'Select Fitness Goals:',
      options: [
        { value: 'weight_loss', label: 'Weight Loss' },
        { value: 'muscle_gain', label: 'Muscle Gain' },
        { value: 'endurance_improvement', label: 'Endurance Improvement' },
        { value: 'flexibility', label: 'Flexibility and Mobility' },
        { value: 'general_fitness', label: 'General Fitness' },
      ],
      required: true
    },
    {
      name: 'currentFitnessLevel',
      type: 'select',
      placeholder: 'Select Current Fitness Level:',
      options: [
        { value: 'beginner', label: 'Beginner' },
        { value: 'intermediate', label: 'Intermediate' },
        { value: 'advanced', label: 'Advanced' },
      ],
      required: true
    },
    {
      name: 'availableEquipment',
      type: 'select',
      placeholder: 'Select Available Equipment:',
      options: [
        { value: 'free_weights', label: 'Free Weights' },
        { value: 'machines', label: 'Machines' },
        { value: 'cardio_equipment', label: 'Cardio Equipment' },
        { value: 'bodyweight_only', label: 'Bodyweight Only' },
        { value: 'multiple', label: 'Multiple' },
      ],
      required: true
    },
    {
      name: 'preferredExerciseType',
      type: 'select',
      placeholder: 'Select Preferred Exercise Type:',
      options: [
        { value: 'strength_training', label: 'Strength Training' },
        { value: 'cardio', label: 'Cardio' },
        { value: 'flexibility', label: 'Flexibility (e.g., Yoga)' },
        { value: 'hiit', label: 'High-Intensity Interval Training (HIIT)' },
        { value: 'functional_training', label: 'Functional Training' },
      ],
      required: true
    },
    { name: 'workoutFrequency', type: 'number', placeholder: 'Workout Frequency (days per week):', required: true },
    { name: 'injuryHistory', type: 'textarea', placeholder: 'Injury History or Medical Conditions (if any):' },
    {
      name: 'timeAvailablePerWorkout',
      type: 'select',
      placeholder: 'Select Time Available per Workout:',
      options: [
        { value: 'short', label: 'Short (30 minutes or less)' },
        { value: 'medium', label: 'Medium (30-60 minutes)' },
        { value: 'long', label: 'Long (over 60 minutes)' },
      ],
      required: true
    },
    { name: 'specificAreasFocus', type: 'text', placeholder: 'Specific Areas of Focus (if applicable):' },
    { name: 'preferences', type: 'textarea', placeholder: 'Preferences (e.g., solo workouts, group classes):' },
    { name: 'motivationalFactors', type: 'textarea', placeholder: 'Motivational Factors (e.g., personal goals, upcoming events):' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_WORKOUT}
      title="Workout Planner"
      imageName="workout_plan"
    />
  );
};

export default WorkoutPlanner;