import React from 'react';
import ToolContent from '../ToolContent';

const DomainNameGenerator = () => {
  const formFields = [
    { name: 'businessName', type: 'text', placeholder: 'Business Name or Keywords:', required: true },
    { name: 'industry', type: 'text', placeholder: 'Industry/Niche:' },
    { name: 'targetAudience', type: 'text', placeholder: 'Target Audience:' },
    { name: 'brandValues', type: 'textarea', placeholder: 'Brand Values/Message:' },
    { name: 'preferredExtensions', type: 'text', placeholder: 'Preferred Domain Extensions (e.g., .com, .net):' },
    { name: 'toneStyle', type: 'text', placeholder: 'Desired Tone/Style:' },
    { name: 'geographicFocus', type: 'text', placeholder: 'Geographic Focus (if any):' },
    {
      name: 'nameLength',
      type: 'select',
      placeholder: 'Select Name Length:',
      options: [
        { value: 'short', label: 'Short' },
        { value: 'medium', label: 'Medium' },
        { value: 'long', label: 'Long' },
      ],
      required: true
    },
    { name: 'avoidedWords', type: 'text', placeholder: 'Avoided Words/Phrases:' },
    { name: 'competitors', type: 'text', placeholder: 'Existing Names/Competitors:' },
  ];

  return (
    <ToolContent
      formFields={formFields}
      apiEndpoint={process.env.REACT_APP_DOMAIN_NAMES}
      title="Domain Name Generator"
      imageName = "domain_name"
    />
  );
};

export default DomainNameGenerator;